:root {
    --bgCopy: #282344;
    --bgDark: #040015;
    --bgBlack: #000000; 
    --bgCard: #131024;
    --bgGray: #515a64;
    --bgSelect: #212646;
    --bgBlue: #2F00ED;
    --bgWhite: #FFFFFF;
    --bgTableGray: #1C2122;
    --textRegular: #AEAEAE;
    --textWhite: #FFFFFF;
    --textPrimary:#F1F1F1;
    --textSecondary: #A8A8A8;
    --textRegular: #757F9C;
    --textCoin:#A8A8A8;
    --textBlue:#13ABED;
    --textGreen: #5CC500;
    --textRed: #f50909;
    --textLink:#397EF6;
    --textOrange: #CB760E;
    --textError: #f72020;
    --btnGrad1: #6613BA;
    --btnGrad2: #2C00AA;
    --btnBorder: #5C5C77;
    --sliderBg:#35383E;
    --sliderBordr:#397EF6;
    --borderPanel: #204483;
    --borderRef: #2C2841;
    --borderLight: #E5E5E5;
    --listHover: #2C2653;
    --inputBorder: #6269A3;
    --placeholderText:#99999A;
    --amountBg:#261E54;
    --darkThemeIcons: url(../images/darktheme-icons.svg);
    --lightThemeIcons: url(https://scmcdn.blob.core.windows.net/scmcdn/suissebase/images/light-theme-icons.svg);
    --cryptoIcons: url(../images/crypto-icons.svg);
}