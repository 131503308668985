/* @import url('../fonts/stylesheet.css'); */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400&display=swap");
* {
  scrollbar-width: thin;
  scrollbar-color: var(--bgGrey) var(--bgDarkGrey);
}
*::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}
html,
.auto-scroll,
.ant-table-body,
.ant-drawer-body,
.mobile-list,
.k-grid-content {
  scrollbar-color: #757f9c transparent;border-radius: 8px;
}
/* body, .ant-layout{background-color: var(--bgLightGray) !important;} */
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p div {
  font-family: "Lato", sans-serif !important;
}
/* body, .ant-layout{background-color: #040015 !important;} */
.icon,
.ant-page-header-back .anticon-arrow-left,
.ant-custumcheck .ant-checkbox-input + span,
.custom-checkbox input[type="checkbox"] + span,
.custom-checkbox input[type="checkbox"]:checked + span,
.k-grouping-row .k-icon,
.k-icon.k-i-more-vertical,
.checkbox-active input[type="checkbox"] + span,
.anticon.ant-alert-icon,
.checkbox-active input[type="checkbox"]:checked + span,
.ant-modal-close-x {
  display: inline-block;
  background: var(--darkThemeIcons);
  vertical-align: middle;
}
body::after { content: ''; background: url(../images/Ellipse.png); position: absolute; bottom: 0; right: 0; width: 100%; height: 100vh; z-index: 0;  background-repeat: no-repeat; }
.ant-modal-close-x {
  background-position: -337px -175px;
  width: 36px;
  height: 36px;
  top: 26px;
  position: absolute;
  right: 22px;
}
.disable-pop .ant-modal-close-x {
  margin: 12px;
}

/* comman css Start*/
.loading-height {
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infinityScrollEndMsg{
  text-align: center;
  background-color:transparent;
  color:white;
  margin-top:24px; 
}
.dashb-btmtable::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.table-scroll::-webkit-scrollbar-thumb {
  background-color: blue;
}
.ant-layout-sider {
  position: fixed;
  top: 54px;
  right: 0;
  z-index: 9999;
}
.strech {
  align-items: stretch;
}
.ant-spin-dot-spin .ant-spin-dot-item {
  display: none;
  transform: none;
  -webkit-animation: none;
  animation: none;
}
.cust-border-loader.input-bordered{height: 53px;}
.cust-border-loader.input-bordered .ant-spin-dot-spin{background-size: contain;    width: 45px;
  height: 45px;}
/* .ant-spin-nested-loading{background: #040015;border-radius: 20px;} */
.ant-spin-dot-spin {
  background-image: url(../images/loader.svg);
  transform: none;
  -webkit-animation: none;
  animation: none;
  width: 70px;
  height: 70px;
}
.container-topspace {
  padding-top: 48px;
}
.ant-empty-img-simple {
  background-image: url(../images/nodata.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 40px;
}
.ant-empty-img-simple-g,
.ant-empty-img-simple-path,
.ant-empty-img-simple-ellipse {
  stroke: transparent;
  fill: transparent;
}
.ant-empty-description {
  color: var(--textSecondary);
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: transparent;
  color: var(--textWhite);
}
.ant-empty.ant-empty-normal {
  background: var(--bgCard);
  padding: 16px 22px;
  border-radius: 20px;
  text-align: center;
  width: 98px;
  margin: 32px auto;
}
.notifications-list .ant-empty.ant-empty-normal {width: auto;}
.notifications-list .ant-spin-container .no-data-height{display: flex;align-items: center;justify-content: center;height: calc(100vh - 200px);}
.custom-modal .ant-empty.ant-empty-normal {
  background: var(--bgDark);
}
/* ant alert  start*/
.ant-layout-content {
  z-index: 999;
}
.ant-alert-warning,
.ant-alert-error {
  background-color: transparent;
  text-align: center;
  border: none;
}
.alert-flex {
  text-align: left;
  align-items: center;
  border-radius: 8px;
  padding: 0px 16px;
  top: -200px;
  background-color: transparent;
  width: 100%;
  color: #ff5747 !important;
  display: flex;
  min-height: 50px;
  border: 1px solid #ff5747;
  left: 0;
  justify-content: space-between;
  margin: 16px 0;
}
.cust-auth-alert {
  text-align: left;
  align-items: center;
  border-radius: 8px;
  padding: 0px 16px;  
  background-color: transparent;
  width: 100%;
  color: #ff5747 !important;
  display: flex;
  min-height: 50px;
  border: 1px solid #ff5747;
  left: 0;
  justify-content: space-between;
  margin: 16px 0;
}
.ant-alert-error .ant-alert-icon,
.ant-alert-warning .ant-alert-icon {
  color: transparent;
}
.ant-alert-description {
  text-align: left;
  color: #ff5747 !important;
  font-size: 15px;
  font-weight: bolder;
}
.anticon.ant-alert-icon {
  background-position: -754px -140px !important;
  margin-right: 10px;
}
.card-paddingrm {
  padding: 0px !important;
}
.card-paddingadd {
  padding: 35px 20px;
}
/* ant alert  end*/
.icon.sm.getcode {
  background-position: -664px -142px;
}
.icon.sm.getcode:hover {
  background-position: -687px -142px;
}
body,
.ant-layout {
  background: url(../images/body-bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; background-color: var(--bgDark) !important;
}
/* section::after{ content: '';background: url(../images/Ellipsecircle.png);position:absolute;bottom: 0;right: 0; width: 100%;height: 100vh;z-index: 0;background-repeat: no-repeat;} */
/* .layout::after{ content: '';background: url(../images/bg-img.png);position:absolute; top:0px; left:0px;width: 100%;height: 280px;z-index: 0;
background-repeat: no-repeat;background-size: cover;} */
.layout-bg {
  border-radius: 8px;
}
.agree-check {
  display: flex;
  align-items: center;
  margin: 20px 0 20px !important;
}
.agree-check div.ant-typography {
  margin-bottom: 0 !important;
}
.agree-check > span {
  margin-right: 10px;
}
.agree-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8a8a8a;
  padding-left: 18px;
}
/* .layout-bg.left-panel{background-color: var(--bgLightBlue);} */
.layout-bg.right-panel {
  background: #040015;
  border: 1px solid #204483;
  box-shadow: 0px 10px 20px rgb(57 126 246 / 15%);
  border-radius: 20px;
  padding: 24px 12px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
.summary-list {
  border: 1px solid var(--borderGrey);
  border-radius: 8px;
  color: var(--textWhite);
}
.summary-total {
  background-color: var(--bgLightGray);
  border-top: 1px solid var(--borderGrey);
  border-radius: 0 0 8px 8px;
}
.minmax {
  border: 1px solid var(--borderGrey);
  padding: 2px;
  border-radius: 4px;
}
.minmax .ant-btn {
  padding: 4px 16px;
  color: var(--textBlue);
  font-size: 12px;
  font-weight: 500;
}
.minmax .ant-btn:hover {
  padding: 4px 16px;
  background-color: var(--textBlue);
  color: var(--textWhite);
}
.ant-row {
  display: flex !important;
}
.row-stretch {
  display: block !important;
}

/* Breadcrumb start */

.cust-breadcrumb.ant-breadcrumb > span:last-child a {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: var(--textWhite) !important;
}
.cust-breadcrumb span:first-child .ant-breadcrumb-link {
  color: var(--textRegular) !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.cust-breadcrumb {
  margin-bottom: 22px;
  margin-left: 10px;
  margin-right: 10px;
}
/* Breadcrumb end */
/* comman css end*/

/* Ions Start*/
.icon.xxl {
  width: 46px;
  height: 46px;
}
.icon.lg {
  width: 26px;
  height: 26px;
}
.icon.md {
  width: 20px;
  height: 20px;
}
.icon.sm {
  width: 18px;
  height: 18px;
}
.icon.xs {
  width: 12px;
  height: 12px;
}

/* xs */
.icon.xs.greenarrow {
  background-position: -183px -146px;
}

/* sm */
.icon.sm.switch-icon {
  background-position: -6px -32px;
}
.icon.sm.bc-arrow {
  background-position: -310px -86px;
}
.icon.sm.card {
  background-position: -63px -7px;
}
.icon.sm.file {
  background-position: -92px -5px;
}
.icon.sm.downarrow {
  background-position: -274px -114px;
}
.icon.sm.copy {
  background-position: -116px -6px;
}
.icon.sm.copy-white {
  background-position: -153px -141px;height: 22px;width: 22px;scale: 0.7; 
}
.icon.sm.open-window {
  background-position: -57px -143px;
}
.icon.sm.close-window {
  background-position: -31px -143px;
}
.icon.sm.white-down-angle {
  background-position: -269px -117px;
  transform: rotateY(180deg);
}
.icon.sm.uparrow-white {
  background-position: -360px -115px;
  transform: rotate(-180deg);
}
.icon.sm.search-blue {
  background-position: -371px -86px;
  margin-right: 24px;
}
.icon.sm.toggle-blue {
  background-position: -396px -86px;
}
.icon.sm.add-coin {
  background-position: -461px -117px;
}
.icon.sm.add-coin:hover {
  background-position: -483px -117px;
}
.icon.sm.add-others {
  background-position: -569px -117px;
}
.icon.sm.add-others:hover {
  background-position: -592px -117px;
}
.icon.lg.greencheck-view{background-position: -26px -27px;}
/* md */
.icon.md.switch-icon {
  background-position: -330px -141px;
  margin: 0 6px 3px;
  cursor: pointer;
}
.icon.md.historyarrow.Cancelled {
  background-position: -59px -30px;
}
.icon.md.historyarrow.Success {
  background-position: -30px -30px;
}
.icon.md.historyarrow.Pending {
  background-position: -88px -30px;
}
.icon.md.left-arrow {
  background-position: -418px -117px;
}
.icon.md.right-arrow {
  background-position: -436px -117px;
}
.icon.md.green-uparrow {
  background-position: -332px -86px;
}
.icon.md.red-downarrow {
  background-position: -350px -85px;
}
.icon.md.down-arrow {
  background-position: -266px -117px;
}
.icon.md.arrow {
  background-position: -6px -116px;
}
.icon.md.success-arrow {
  background-position: -30px -30px;
}
.icon.md.pending-arrow {
  background-position: -58px -30px;
}
.icon.md.cancel-arrow {
  background-position: -89px -30px;
}
.icon.md.info {
  background-position: -80px -342px;
  transform: scale(0.8);
  width: 28px;
  height: 38px;
}
.icon.md.greenCheck {
  background-position: -71px -71px;
}
.icon.md.filter {
  background-position: -123px -269px;
}
.icon.md.up-arrow {
  background-position: -219px -117px;
}
.icon.md.yen {
  background-position: -8px -426px;
  width: 22px;
  height: 22px;
}
.icon.md.yen:hover {
  background-position: -8px -458.5px;
  width: 22px;
  height: 22px;
}
.icon.md.usd {
  background-position: -103px -427px;
  width: 22px;
  height: 22px;
}
.icon.md.sell {
  background-position: -423px -85px;
}
.icon.md.buy {
  background-position: -453px -85px;
}
.icon.md.withdraw {
  background-position: -477px -85px;
  transform: rotate(-36deg);
}
.icon.md.deposit {
  background-position: -502px -85px;
  transform: rotate(-40deg);
}
.icon.md.menubar {
  background-position: -703px -140px;
}

/* .icon.md.usd{background-position: -103px -459.5px;width: 22px;height: 22px;} */
.icon.md.gbp {
  background-position: -39px -427px;
}
.icon.md.gbp:hover {
  background-position: -39px -459.5px;
}
.icon.md.chf {
  background-position: -127px -426.5px;
}
.icon.md.chf:hover {
  background-position: -126px -459px;
}
.icon.md.sgd {
  background-position: -154px -426.5px;
  transform: scale(1.2);
}
.icon.md.sgd:hover {
  background-position: -154px -459px;
  transform: scale(1.2);
}
.icon.md.usd.active {
  background-position: -103px -459.5px;
  width: 22px;
  height: 22px;
}
.icon.md.usd:hover {
  background-position: -103px -459.5px;
  width: 22px;
  height: 22px;
}
.icon.md.pound {
  background-position: -40px -426px;
  width: 22px;
  height: 22px;
}
.icon.md.pound:hover {
  background-position: -40px -459.5px;
  width: 22px;
  height: 22px;
}
.icon.md.eur {
  background-position: -73px -427px;
  width: 22px;
  height: 22px;
}
.icon.md.eur:hover {
  background-position: -73px -459.5px;
  width: 22px;
  height: 22px;
}
.icon.md.edit {
  background-position: -272px -141px;
  width: 22px;
  height: 21px;
}
.icon.md.edit:hover {
  background-position: -475px -141px;
}
.active .icon.md.eur {
  background-position: -73px -459.5px;
}
.active .icon.md.usd {
  background-position: -103px -459.5px;
  width: 22px;
  height: 22px;
}
.active .icon.md.gbp {
  background-position: -39px -459.5px;
}
.active .icon.md.chf {
  background-position: -126px -459px;
}
.ant-tabs-tab.ant-tabs-tab-active .icon.md.usd {
  background-position: -103px -459.5px;
}
.ant-tabs-tab.ant-tabs-tab-active .icon.md.eur {
  background-position: -73px -460px;
}
.ant-tabs-tab.ant-tabs-tab-active .icon.md.pound {
  background-position: -40px -459px;
}
.ant-tabs-tab.ant-tabs-tab-active .icon.md.yen {
  background-position: -8px -459.5px;
}
/* lg */
.icon.lg.add-icon {
  background-position: -187px -82px;
  transform: rotate(45deg);
}
/* .icon.lg.close {
  background-position: -187px -82px;
} */
.icon.lg.copy {
  background-position: -94px 2px;
}
.icon.lg.back {
  background-position: -293px -113px;
}
.icon.lg.checkbox {
  background-position: -8px -297px;
}
.icon.lg.settings-icon {
  background-position: -44px -351px;
}
.icon.lg.refresh {
  background-position: -100px -82px;
}
.icon.lg.bell {
  background-position: -73px -82px;
}
.icon.lg.hamburg-icon {
  background-position: -40px -82px;
}
.icon.lg.menu {
  background-position: -4px -4px;
}
/* .icon.lg.close {
  background-position: -187px -79px;
} */
.icon.lg.filter {
  background-position: -722px -137px;
}
.icon.lg.right-arrow {
  background-position: -376px -180px;
}
/* .icon.lg.close {
  background-position: -342px -180px;
} */
.icon.lg.bank {
  background-position: -403px -179px;
}
.icon.lg.close-blue {
  background-position: -342px -179px;
}
.icon.lg.square-arrow {
  background-position: -53px -141px;
  margin-top: 8px;
}
.icon.lg.fb {
  background-position: -351px -138px;
}
.icon.lg.whatsapp {
  background-position: -377px -136px;
}
.icon.lg.twitter {
  background-position: -408px -138px;
}
.icon.lg.mail {
  background-position: -442px -137px;
}
/* .ant-modal-close-x{background-position: -342px -179px;width: 26px;height: 26px;margin:35px 20px;} */

/* xxl */
.icon.xxl.cart {
  background-position: -8px -616px;
  transform: scale(0.5);
}
.icon.xxl.uparrow {
  background-position: -95px -617px;
  transform: scale(0.5);
  transform: rotate(138deg) !important;
}
.icon.xxl.downarrow {
  background-position: -266px -618px;
  transform: scale(0.5);
  transform: rotate(142deg) !important;
}
.icon.xxl.wallet {
  background-position: -181px -617px;
  transform: scale(0.5);
}
.customicon:hover .icon.xxl.cart {
  background-position: -8px -550px;
  transform: scale(0.5);
}
.customicon:hover .icon.xxl.downarrow {
  background-position: -266px -551px;
  transform: scale(0.5);
}
.customicon:hover .icon.xxl.uparrow {
  background-position: -95px -550px;
  transform: scale(0.5);
}
.customicon:hover .icon.xxl.wallet {
  background-position: -181px -552px;
  transform: scale(0.5);
}
.icon.xxl.in {
  background-position: 0px -170px;
}
.icon.xxl.google {
  background-position: -96px -170px;
}
.icon.xxl.twitter {
  background-position: -48px -171px;
}
.icon.xxl.copy {
  background-position: -187px -170px;
}
.icon.xxl.btn-arrow {
  background-position: -283px -170px;
}
.icon.lg.btn-arrow-back {
  background-position: -373px -181px;
  transform: rotate(180deg);
  margin-right: 8px;
}
.ant-page-header-back .anticon-arrow-left {
  width: 20px;
  height: 20px;
  background-position: -4px -140px;
  transform: scale(1.2);
}
.ant-page-header-back .anticon-arrow-left svg {
  display: none;
}
.add-transfer {
  float: right;
  background: var(--bgLightGray);
  padding: 10px;
  border-radius: 8px;
  margin: 12px 16px 12px 0;
}
.coin {
  background: var(--cryptoIcons) no-repeat;
  display: inline-block;
}

/* Coin - XS */
/* .coin.sm{width: 25px;height: 25px;} */

/* sm */

.coin.sm.btc {
  background-position: -11px -749px;
}
.coin.sm.eth {
  background-position: -44px -749px;
}
.coin.sm.xrp {
  background-position: -175px -749px;
}
.coin.sm.usdt {
  background-position: -76px -749px;
}
.coin.sm.usdc {
  background-position: -109px -749px;
}
.coin.sm.usd {
  background-position: -172px -3px;
}
.coin.sm.gbp {
  background-position: -214px -3px;
}
.coin.sm.eur {
  background-position: -194px -3px;
}
.coin.sm.ltc {
  background-position: -142px -789px;
}
.coin.sm.mkr {
  background-position: -371px -831px;
}
.coin.sm.dot {
  background-position: -240px -749px;
}
.coin.sm.doge,.coin.sm.dog {
  background-position: -273px -749px;
}
.coin.sm.ada {
  background-position: -207px -749px;
}
.icon.sm.green-arrow {
  background-position: -332px -86px;
}
.icon.sm.alert-close {
  background-position: -788px -142px !important;
  transform: scale(0.6);
  cursor: pointer;
  flex: 20px 0 0;
  flex-shrink: 0;border: none;
}

.coin-item.active .coin.sm.eth {
  background-position: -44px -533px;
}
.coin-item.active .coin.sm.btc {
  background-position: -11px -533px;
}
.coin-item.active .coin.sm.xrp {
  background-position: -175px -533px;
}
.coin-item.active .coin.sm.usdt {
  background-position: -76.5px -533px;
}
.coin-item.active .coin.sm.usdc {
  background-position: -109.3px -533px;
}
.coin-item.active .coin.sm.usd {
  background-position: -44px -571px;
}
.coin-item.active .coin.sm.gbp {
  background-position: -214px -3px;
}
.coin-item.active .coin.sm.eur {
  background-position: -469px -533px;
}
.coin-item.active .coin.sm.ltc {
  background-position: -142px -571px;
}
.coin-item.active .coin.sm.mkr {
  background-position: -371px -612.5px;
}
.coin-item.active .coin.sm.dot {
  background-position: -240px -533px;
}
.coin-item.active .coin.sm.doge {
  background-position: -273px -533px;
}
.coin-item.active .coin.sm.ada {
  background-position: -207.5px -533px;
}
.coin-item.active .icon.sm.green-arrow {
  background-position: -332px -86px;
}

/*Coin -  md */
.coin.md,
.coin-droupdown .ant-select-selection-item .coin.sm {
  width: 36px;
  height: 36px;
}
.coin.md.btc,
.coin-droupdown .ant-select-selection-item .coin.sm.btc {
  background-position: -4px -169px;
}
.coin.md.eth,
.coin-droupdown .ant-select-selection-item .coin.sm.eth {
  background-position: -81px -167px;
}
.coin.md.xrp,
.coin-droupdown .ant-select-selection-item .coin.sm.xrp {
  background-position: -674px -167px;
}
.coin.md.usdt,
.coin-droupdown .ant-select-selection-item .coin.sm.usdt {
  background-position: -437px -167px;
}
.coin.md.usdc,
.coin-droupdown .ant-select-selection-item .coin.sm.usdc {
  background-position: -398px -167px;
}
.coin.md.btc {
  background-position: -4px -170px;
  transform: scale(0.9);
}
.icon.md.search {
  background-position: -147px -113px;
}
.cust-lg.coin.md.eth {
  background-position: -83px -169px;
  transform: scale(1.5);
}
.cust-lg.md.btc {
  background-position: -3px -745px;
  transform: scale(2);
  margin-right: 10px;
}
.cust-lg.md.usd {
  background-position: -400px -169px;
  transform: scale(1.5);
  margin-right: 10px;
}
.cust-lg.md.gbp {
  background-position: -360px -169px;
  transform: scale(1.5);
  margin-right: 10px;
}
.cust-lg.md.eur {
  background-position: -281px -169px;
  transform: scale(1.5);
  margin-right: 10px;
}
.cust-lg.md.usdc {
  background-position: -201px -169px;
  transform: scale(1.5);
  margin-right: 10px;
}
.cust-lg.md.usdt {
  background-position: -241px -167px;
  transform: scale(1.5);
  margin-right: 10px;
}
.cust-lg.coin.md.mkr {
  background-position: -403px -118px;
  transform: scale(1.5);
  margin-right: 10px;
}
.buy-sctcoin.coin.md.eur {
  background-position: -259px -38px;
}
.buy-sctcoin.coin.md.usd {
  background-position: -367px -38px;
}
/* .buy-sctcoin.coin.md.undefined{background-position: -9px -39px;}
.cust-lg.coin.md.undefined{background-position: -9px -39px;transform: scale(1.5);margin-right: 10px;} */
.buy-sctcoin.coin.md.eth {
  background-position: -78px -39px;
}
.buy-sctcoin.coin.md.btc {
  background-position: -9px -39px;
}
.buy-sctcoin.coin.md.usdt {
  background-position: -404px -39px;
}
.buy-sctcoin.coin.md.usdc {
  background-position: -473px -76px;
}
.buy-sctcoin.coin.md.xrp {
  background-position: -619px -39px;
}
.buy-sctcoin.coin.md.ada {
  background-position: -403px -76px;
}
.buy-sctcoin.coin.md.ltc {
  background-position: -9px -76px;
}
.buy-sctcoin.coin.md.mkr {
  background-position: -403px -118px;
}
.buy-sctcoin.coin.md.dot {
  background-position: -113px -39px;
}
.buy-sctcoin.coin.md.doge {
  background-position: -508px -39px;
}

/* lg */
.coin.lg {
  width: 60px;
  height: 60px;
}
.coin.lg.btc {
  background-position: -9px -383px;
}
.coin.lg.etc {
  background-position: -9px -383px;
}
.coin.lg.eur {
  background-position: -506px -383px;
}
.coin.lg.usd {
  background-position: -721px -383px;
}
.coin.lg.gbp {
  background-position: -650px -383px;
}
/* .cust-lg.coin.lg.undefined{background-position: -8px -385px;} */
.cust-lg.coin.lg.btc {
  background-position: 16px -730px;
  transform: scale(2);
  margin-right: 10px;
}
.cust-lg.coin.lg.eth {
  background-position: -151px -385px;
}
.cust-lg.coin.lg.usdt {
  background-position: -79px -452px;
}
.cust-lg.coin.lg.usdc {
  background-position: -722px -383px;
}
.cust-lg.coin.lg.xrp {
  background-position: -6px -452px;
}
.cust-lg.coin.lg.ada {
  background-position: -221px -452px;
}
.cust-lg.coin.lg.ltc {
  background-position: -293px -452px;
}
.cust-lg.coin.lg.mkr {
  background-position: -148px -452px;
}
.cust-lg.coin.lg.dot {
  background-position: -222px -383px;
}
.cust-lg.coin.lg.doge {
  background-position: -365px -452px;
}
/* Icons End*/

/*crypto coin icons  */
.active-coin.crypto.coin.sm.btc {
  background-position: -11px -533px;flex-shrink: 0;
}
.active-coin.crypto.coin.sm.eth {
  background-position: -44px -533px;
}
.active-coin.crypto.coin.sm.usdt {
  background-position: -76.5px -533px;
}
.active-coin.crypto.coin.sm.usdc {
  background-position: -109.3px -533px;
}

.active-coin.crypto.coin.sm.xrp {
  background-position: -174.8px -533px;
}
.active-coin.crypto.coin.sm.ada {
  background-position: -207.5px -533px;
}
.active-coin.crypto.coin.sm.dot {
  background-position: -240px -533px;
}
.active-coin.crypto.coin.sm.doge {
  background-position: -273px -533px;
}
.active-coin.crypto.coin.sm.chz {
  background-position: -306px -533px;
}
.active-coin.crypto.coin.sm.aurora {
  background-position: -338px -533px;
}
.active-coin.crypto.coin.sm.xlm {
  background-position: -371px -533px;
}
.active-coin.crypto.coin.sm.bch {
  background-position: -404px -533px;
}
.active-coin.crypto.coin.sm.dnt {
  background-position: -436px -533px;
}
.active-coin.crypto.coin.sm.eur {
  background-position: -469.3px -533px;
}



.active-coin.crypto.coin.sm.usd {
  background-position: -43.9px -571px;
}
.active-coin.crypto.coin.sm.gusd {
  background-position: -77px -571px;
}
.active-coin.crypto.coin.sm.zil {
  background-position: -109px -571px;
}
.active-coin.crypto.coin.sm.ltc {
  background-position: -142px -571px;
}
.active-coin.crypto.coin.sm.fill {
  background-position: -175px -571px;
}
.active-coin.crypto.coin.sm.amp {
  background-position: -207px -571px;
}
.active-coin.crypto.coin.sm.vet {
  background-position: -240px -571px;
}
.active-coin.crypto.coin.sm.busd {
  background-position: -273px -571px;
}
.active-coin.crypto.coin.sm.theta {
  background-position: -306px -571px;
}

.active-coin.crypto.coin.sm.link {
  background-position: -371px -571px;
}
.active-coin.crypto.coin.sm.btcst {
  background-position: -404px -571px;
}
.active-coin.crypto.coin.sm.miota {
  background-position: -436px -571px;
}
.active-coin.crypto.coin.sm.xdc {
  background-position: -502px -571px;
}

.active-coin.crypto.coin.sm.eos {
  background-position: -44px -613px;
}
.active-coin.crypto.coin.sm.shib {
  background-position: -77px -613px;
}
.active-coin.crypto.coin.sm.aave {
  background-position: -109px -613px;
}
.active-coin.crypto.coin.sm.noia {
  background-position: -142px -613px;
}

.active-coin.crypto.coin.sm.ksm {
  background-position: -207px -613px;
}
.active-coin.crypto.coin.sm.algo {
  background-position: -240px -613px;
}
.active-coin.crypto.coin.sm.cake {
  background-position: -306px -613px;
}
.active-coin.crypto.coin.sm.tfuel {
  background-position: -338px -613px;
}
.active-coin.crypto.coin.sm.mkr {
  background-position: -371px -612.5px;
}
.active-coin.crypto.coin.sm.rune {
  background-position: -404px -613px;
}

.active-coin.crypto.coin.sm.dai {
  background-position: -469px -613px;
}

.active-coin.crypto.coin.sm.lunc {
  background-position: -44px -655px;
}
.active-coin.crypto.coin.sm.avax {
  background-position: -77px -655px;
}
.active-coin.crypto.coin.sm.saito {
  background-position: -109px -655px;
}

.active-coin.crypto.coin.sm.undefined {
  background-position: -174px -873px;
}


/*HEADER xxl crypto icons md transform start*/
/* .cust-xxl.crypto.coin.md.btc{background-position: -5px -528px;transform: scale(1.8);} */

.cust-xxl.crypto.coin.sm.btc {
  background-position: -11.2px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.eth {
  background-position: -44px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.usdt {
  background-position: -76.5px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.usdc {
  background-position: -109.3px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.name {
  background-position: -142px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.xrp {
  background-position: -174.8px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.ada {
  background-position: -207.5px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.dot {
  background-position: -240px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.doge {
  background-position: -273px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.chz {
  background-position: -306px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.aurora {
  background-position: -338px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.xlm {
  background-position: -371px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.bch {
  background-position: -404px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.dnt {
  background-position: -436px -533px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.eur {
  background-position: -469.3px -533px;
  transform: scale(1.8);
}



.cust-xxl.crypto.coin.sm.usd {
  background-position: -43.9px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.gusd {
  background-position: -77px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.zil {
  background-position: -109px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.ltc {
  background-position: -142px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.fill {
  background-position: -175px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.amp {
  background-position: -207px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.vet {
  background-position: -240px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.busd {
  background-position: -273px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.theta {
  background-position: -306px -571px;
  transform: scale(1.8);
}

.cust-xxl.crypto.coin.sm.link {
  background-position: -371px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.btcst {
  background-position: -404px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.miota {
  background-position: -436px -571px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.xdc {
  background-position: -502px -571px;
  transform: scale(1.8);
}


.cust-xxl.crypto.coin.sm.eos {
  background-position: -44px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.shib {
  background-position: -77px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.aave {
  background-position: -109px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.noia {
  background-position: -142px -613px;
  transform: scale(1.8);
}

.cust-xxl.crypto.coin.sm.ksm {
  background-position: -207px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.algo {
  background-position: -240px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.atom {
  background-position: -273px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.cake {
  background-position: -306px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.tfuel {
  background-position: -338px -613px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.mkr {
  background-position: -371px -612.5px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.rune {
  background-position: -404px -613px;
  transform: scale(1.8);
}

.cust-xxl.crypto.coin.sm.dai {
  background-position: -469px -613px;
  transform: scale(1.8);
}
/* .cust-xxl.crypto.coin.sm.xmr {
  background-position: -502px -613px;
  transform: scale(1.8);
} */
.cust-xxl.crypto.coin.sm.lunc {
  background-position: -44px -655px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.avax {
  background-position: -77px -655px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.saito {
  background-position: -109px -655px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.undefined {
  background-position: -174px -873px;
  transform: scale(1.8);
}
.cust-xxl.crypto.coin.sm.bnb {
  background-position: -141px -533px;
  transform: scale(1.8);
}
/*HEADER crypto icons md transform end*/

/*left panel on hover crypto icons start*/

.coin-item.active .crypto.coin.sm.btc {
  background-position: -11px -533px;
}
.coin-item.active .crypto.coin.sm.eth {
  background-position: -44px -533px;
}
.coin-item.active .crypto.coin.sm.usdt {
  background-position: -76.5px -533px;
}
.coin-item.active.crypto.coin.sm.usdc {
  background-position: -109.3px -533px;
}
.coin-item.active .crypto.coin.sm.name {
  background-position: -142px -533px;
}
.coin-item.active .crypto.coin.sm.xrp {
  background-position: -174.8px -533px;
}
.coin-item.active .crypto.coin.sm.ada {
  background-position: -207.5px -533px;
}
.coin-item.active .crypto.coin.sm.dot {
  background-position: -240px -533px;
}
.coin-item.active .crypto.coin.sm.doge {
  background-position: -273px -533px;
}
.coin-item.active .crypto.coin.sm.chz {
  background-position: -306px -533px;
}
.coin-item.active .crypto.coin.sm.aurora {
  background-position: -338px -533px;
}
.coin-item.active .crypto.coin.sm.xlm {
  background-position: -371px -533px;
}
.coin-item.active .crypto.coin.sm.bch {
  background-position: -404px -533px;
}
.coin-item.active .crypto.coin.sm.dnt {
  background-position: -436px -533px;
}
.coin-item.active .crypto.coin.sm.eur {
  background-position: -469.3px -533px;
}
.coin-item.active .crypto.coin.sm.gbp {
  background-position: -499.9px -533px !important;
}
.coin-item.active .crypto.coin.sm.chf {
  background-position: -172.9px -571px;
}



.coin-item.active .crypto.coin.sm.usd {
  background-position: -43.9px -571px;
}
.coin-item.active .crypto.coin.sm.gusd {
  background-position: -77px -571px;
}
.coin-item.active .crypto.coin.sm.zil {
  background-position: -109px -571px;
}
.coin-item.active .crypto.coin.sm.ltc {
  background-position: -142px -571px;
}
.coin-item.active .crypto.coin.sm.fill {
  background-position: -175px -571px;
}
.coin-item.active .crypto.coin.sm.amp {
  background-position: -207px -571px;
}
.coin-item.active .crypto.coin.sm.vet {
  background-position: -240px -571px;
}
.coin-item.active .crypto.coin.sm.busd {
  background-position: -273px -571px;
}
.coin-item.active .crypto.coin.sm.theta {
  background-position: -306px -571px;
}

.coin-item.active .crypto.coin.sm.link {
  background-position: -371px -571px;
}
.coin-item.active .crypto.coin.sm.btcst {
  background-position: -404px -571px;
}
.coin-item.active .crypto.coin.sm.miota {
  background-position: -436px -571px;
}
.coin-item.active .crypto.coin.sm.xdc {
  background-position: -502px -571px;
}


.coin-item.active .crypto.coin.sm.eos {
  background-position: -44px -613px;
}
.coin-item.active .crypto.coin.sm.shib {
  background-position: -77px -613px;
}
.coin-item.active .crypto.coin.sm.aave {
  background-position: -109px -613px;
}
.coin-item.active .crypto.coin.sm.noia {
  background-position: -142px -613px;
}

.coin-item.active .crypto.coin.sm.ksm {
  background-position: -207px -613px;
}
.coin-item.active .crypto.coin.sm.algo {
  background-position: -240px -613px;
}
.coin-item.active .crypto.coin.sm.atom {
  background-position: -273px -613px;
}
.coin-item.active .crypto.coin.sm.cake {
  background-position: -306px -613px;
}
.coin-item.active .crypto.coin.sm.tfuel {
  background-position: -338px -613px;
}
.coin-item.active .crypto.coin.sm.mkr {
  background-position: -371px -612.5px;
}
.coin-item.active .crypto.coin.sm.rune {
  background-position: -404px -613px;
}
.coin-item.active .crypto.coin.sm.dai {
  background-position: -469px -613px;
}
/* .coin-item.active .crypto.coin.sm.xmr {
  background-position: -502px -613px;
} */
.coin-item.active .crypto.coin.sm.lunc {
  background-position: -44px -655px;
}
.coin-item.active .crypto.coin.sm.avax {
  background-position: -77px -655px;
}
.coin-item.active .crypto.coin.sm.saito {
  background-position: -109px -655px;
}
.coin-item.active .crypto.coin.sm.undefined {
  background-position: -174px -873px;
}
.coin-item.active .crypto.coin.sm.bnb {
  background-position: -140.5px -533px;
}
/*left panel on hover crypto icons end*/

/*left panel crypto icons start*/
.coin.sm {
  width: 27px;
  height: 25px;
}
.crypto.coin.sm.chf {
  background-position: -172.3px -789px;
}
.crypto.coin.sm.gbp {
  background-position: -500.3px -749px;
}
.crypto.coin.sm.btc {
  background-position: -11px -749px;
}
.crypto.coin.sm.transak {
  background-position: -570px -534px;
}
.crypto.coin.sm.encryptus {
  background-position: -603px -533px;
}
.coin-title.non-cust-cointitle{display: inline-block;width: 90px;}
.crypto.coin.sm.matic{background-position:-337px -789px;}
.crypto.coin.sm.sol{background-position:-403px -789px;}
.crypto.coin.sm.steth{background-position:-634px -749px;}
.crypto.coin.sm.cbeth{background-position:-668px -749px;}
.crypto.coin.sm.weth{background-position:-698px -749px;}
.crypto.coin.sm.mco2{background-position:-730px -749px;}
.crypto.coin.sm.sdai, .crypto.coin.sm.spsdai {background-position:-534px -831px;}
.crypto.coin.sm.asteth {background-position:-570px -831px;}
.crypto.coin.sm.ybt {background-position: -763px -750px;}
.crypto.coin.sm.aethweth, .crypto.coin.sm.aethusdc, .crypto.coin.sm.spweth, .crypto.coin.sm.pt-steaklrt-25jul2024{background-position:-337px -789px;}
.crypto.coin.sm.eth {
  background-position: -44px -749px;
}
.crypto.coin.sm.usdt {
  background-position: -76.5px -749px;
}
.crypto.coin.sm.usdc {
  background-position: -109.3px -749px;
}
.crypto.coin.sm.name {
  background-position: -142px -749px;
}
.crypto.coin.sm.xrp {
  background-position: -174.8px -749px;
}
.crypto.coin.sm.ada {
  background-position: -207.5px -749px;
}
.crypto.coin.sm.dot {
  background-position: -240px -749px;
}
.crypto.coin.sm.doge,.crypto.coin.sm.dog {
  background-position: -273px -749px;
}
.crypto.coin.sm.chz {
  background-position: -306px -749px;
}
.crypto.coin.sm.aurora {
  background-position: -338px -749px;
}
.crypto.coin.sm.xlm {
  background-position: -371px -749px;
}
.crypto.coin.sm.bch {
  background-position: -404px -749px;
}
.crypto.coin.sm.dnt {
  background-position: -436px -749px;
}
.crypto.coin.sm.eur {
  background-position: -469.3px -749px;
}

.crypto.coin.sm.usd {
  background-position: -43.9px -789.2px;
}
.crypto.coin.sm.gusd {
  background-position: -77px -789px;
}
.crypto.coin.sm.zil {
  background-position: -109px -789px;
}
.crypto.coin.sm.ltc {
  background-position: -142px -789px;
}
.crypto.coin.sm.fill {
  background-position: -175px -789px;
}
.crypto.coin.sm.amp {
  background-position: -207px -789px;
}
.crypto.coin.sm.vet {
  background-position: -240px -789px;
}
.crypto.coin.sm.busd {
  background-position: -273px -789px;
}
.crypto.coin.sm.theta {
  background-position: -306px -789px;
}

.crypto.coin.sm.link {
  background-position: -371px -789px;
}

.crypto.coin.sm.miota {
  background-position: -436px -789px;
}
.crypto.coin.sm.uni, .crypto.coin.sm.uni-v2 {
  background-position: -469px -789px;
}
.crypto.coin.sm.xdc {
  background-position: -502px -789px;
}
.crypto.coin.sm.eos {
  background-position: -44px -831px;
}
.crypto.coin.sm.shib {
  background-position: -77px -831px;
}
.crypto.coin.sm.aave {
  background-position: -109px -831px;
}
.crypto.coin.sm.noia {
  background-position: -142px -831px;
}
.crypto.coin.sm.ksm {
  background-position: -207px -831px;
}
.crypto.coin.sm.algo {
  background-position: -240px -831px;
}
.crypto.coin.sm.atom {
  background-position: -273px -831px;
}
.crypto.coin.sm.cake {
  background-position: -306px -831px;
}
.crypto.coin.sm.tfuel {
  background-position: -338px -831px;
}
.crypto.coin.sm.mkr {
  background-position: -371px -830.5px;
}
.crypto.coin.sm.rune {
  background-position: -404px -831px;
}
.crypto.coin.sm.dai {
  background-position: -469px -831px;
}
/* .crypto.coin.sm.xmr {
  background-position: -502px -831px;
} */

/* .crypto.coin.sm.xmr {
  background-position: -11px -873px;
} */
.crypto.coin.sm.lunc {
  background-position: -44px -873px;
}
.crypto.coin.sm.avax {
  background-position: -77px -873px;
}
.crypto.coin.sm.saito {
  background-position: -109px -873px;
}

.crypto.coin.sm.undefined {
  background-position: -174px -873px;
}

.crypto.coin.sm.btcst {
  background-position: -142px -873px;
}
.crypto.coin.sm.bnb {
  background-position: -141.5px -749px;
}
/*left panel crypto icons end*/

/* Header Start */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1029;
}
.head-menutogle {
  margin-right: 34px;
  margin-left: 8px;
}
.head-menutogle img {
  transform: scale(0.5);
}
.head-username {
  color: var(--textSecondary);
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0 !important;
}
.head-businesname {
  margin-bottom: 0 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--textWhite);
  text-align: left;
}
.head-textfield {
  margin: 0px 14px 0 4px;
}
.head-button {
  border: 1px solid var(--bgSelect);
  padding: 10px;
  border-radius: 40px;
}
.head-button:hover {
  border: 0;
}
.header-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.header-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding-right: 0 !important;
  padding-left: 18px !important;
}
.login-user-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.dropdown-img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;object-fit: cover;
}
.menu-dropdown .login-user-img {
  border-radius: 50%;
  border: 2px solid var(--bgTableGray);
}
.profile-dropdown {
  display: flex;
  align-items: center;
}
.profile-dropdown-menu {
  padding: 12px 34px;
  text-align: center;
}
.header-right.ant-menu-horizontal > .ant-menu-item::after {
  content: none;
}
.secureDropdown .profile-dropdown-menu {
  background-color: var(--bgCard);
  color: var(--textWhite);
  border-radius: 20px;
  min-width: 270px;
  max-width: 270px;
  padding: 24px 0;
  border: 1px solid #204483;
}
.secureDropdown .ant-dropdown-arrow {
  border-color: var(--bgCopy) !important;
}
.secureDropdown .ant-menu-title-content {
  line-height: 23px;
}
.drpdwn-list {
  padding: 12px;
  color: var(--textWhite);
  text-align: center;
  margin: 0 10px;
  list-style: none;
  background: var(--bgCard);
}
.drpdwn-list .head-exit {
  color: var(--textWhite);
  text-transform: uppercase;
}
.profile-btn,
.profile-btn:active,
.profile-btn:focus,
.profile-btn:hover {
  background: var(--bgDarkGrey);
  border: 1px solid #ed4b9e;
  height: 38px;
  border-radius: 19px;
  color: var(--bgTableGray);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 24px;
  padding: 10px 14px;
  width: 200px;
}
.custom-header {
  display: flex;
  justify-content: space-between;
  background: transparent;
  z-index: 100;
  align-items: center;
  padding: 8px 0px;
}
.custom-header ul {
  background: transparent;
  border: 0;
  padding-left: 0 !important;
}
.header-right li:first-child {
  padding-right: 0 !important;
}
.header-right .mr {
  margin-right: -20px !important;
}
.menu-item {
  display: inline-flex;
  list-style: none;
  margin-bottom: 0 !important;
  align-items: center;
}
.menu-item .list-item {
  margin: 0px 20px;
  font-size: 16px;
  font-weight: 400;
}
.menu-item .list-item a,
.menu-item .list-item {
  color: var(--textWhite);
}
.main-container,
.main-menu .list-item {
  position: relative;
}
.main-menu .list-item:first-child:hover::after {
  content: none;
}
/* Header End */

/* kendo grid start */
.exchnage-transaction.k-grid {
  border: 0;
}
.k-i-more-vertical::before {
  display: none;
}
.k-icon.k-i-more-vertical {
  background-position: -332px -86px;
}
.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-header-column-menu:hover,
.k-grid-header .k-grid-header-menu:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover {
  background-color: transparent !important;
}
.custom-grid {
  margin: 10px;
}
.custom-grid .k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  background-color: transparent !important;
}
.custom-grid.k-grid tr.k-alt {
  background-color: transparent !important;
}
.custom-grid.k-grid tr,
.k-grid tr td,
.k-grid tr th {
  border-bottom: 1px solid #f0f0f0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 16px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5a575c;
}
.custom-grid.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
  background-color: transparent !important;
}
.custom-grid.k-grid {
  border-color: transparent !important;
  background-color: transparent !important;
}
.custom-grid .k-grid-content {
  overflow-y: auto;
  background-color: transparent !important;
}
.custom-grid .k-grid-header-wrap,
.k-grid-footer-wrap {
  border-width: 0 !important;
}
.custom-grid .k-pager-numbers .k-link.k-selected {
  color: var(--textBlue);
  background-color: #2f00ed1f;
}
.custom-grid .k-pager-numbers .k-link {
  color: var(--textBlue);
}
.custom-grid .k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-hover {
  color: var(--textBlue);
  background-color: #2f00ed1f;
}
.k-list-item.k-selected {
  background-color: var(--textBlue) !important;
}
.custom-grid .k-picker-solid:focus,
.k-picker-solid.k-focus {
  box-shadow: none;
}
.custom-grid .k-column-title {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: var(--textSecondary);
}
.k-actions.k-actions-stretched
  .k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md {
  background-color: #e8e8e8 !important ;
  border-radius: 30px;
}
.k-actions.k-actions-stretched .k-button-solid-primary.k-disabled {
  background-color: var(--bgBlue) !important ;
  border-radius: 30px;
  border: transparent;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
.k-actions.k-actions-stretched .k-button-solid-primary {
  background-color: var(--bgBlue) !important ;
  border-radius: 30px;
  border: transparent;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}
col.k-sorted,
th.k-sorted {
  background-color: transparent !important;
}
.custom-grid .k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-i-sort-asc-small,
.k-grid-header .k-i-sort-desc-small,
.k-grid-header .k-sort-order {
  color: var(--textSecondary) !important;
}
.pannel-bg .k-grid-header,
.pannel-bg .k-grid-container table {
  background: var(--bgCard);
}
.pannel-bg .k-column-title {
  color: #a8a8a8;
}
.pannel-bg .k-grid-header {
  border-bottom: 1px solid #393e64;
}
.pannel-bg .k-grid-container table tr td {
  color: #f1f1f1;
  border-bottom: 1px solid #393e64;
}
.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
  background-color: transparent !important;
}
.pannel-bg .k-grid-content {
  overflow: inherit !important;
}
/* kendo grid End */

/* buy/sell component styles start */
.withdraw-slider .ant-slider-mark .ant-slider-mark-text:last-child strong {
  margin-left: 0 !important;
}
.buy-slider.slider-class span:nth-child(2) {
  width: 150px;
}
.buy-entryinput {
  font-weight: 500;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  color: var(--textWhite);
}
.buy-token {
  background: var(--bgSelect);
  border-radius: 8px;
  min-width: 150px;
  display: inline-block;
}
.buy-coinselect {
  background-color: var(--bgLightGray);
  padding: 4px 16px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  height: 54px;
}
.buy-from,
.buy-fromcurrency {
  font-size: 14px;
  line-height: 35px;
  text-align: center;
  margin-left: 4px;
  margin-right: 4px;
  text-transform: capitalize;
  color: var(--placeholderText);
}
.buy-selectgroup {
  display: flex;
  align-items: center;
  width: 100%;
  color: #e5e5e5;
}
.buy-selecttoken {
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  height: 142px;
}
.panel-card.buy-card {
  background-color: var(--bgCard);
  border-radius: 20px;
  padding: 35px 20px;
}
.buy-sctcoin {
  margin: 0 auto;
}
.buy-switchtext {
  color: var(--textPrimary);
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px !important;
}
.buy-toinput {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: var(--textWhite);
}
.buy-toamount {
  width: 215px;
  height: 40px;
  background: var(--amountBg);
  border-radius: 19px;
  align-items: center;
  display: flex;
  margin: 14px auto;
  padding: 4px 18px;
}
.buy-toheading span {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
  color: var(--textSecondary);
  margin: 0 4px;
}
.buy-toheading {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: var(--textWhite);
  display: flex;
  background: transparent;
  border: 0;
  margin: 0 auto;
}
.buy-toheading .ant-input {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: var(--textWhite);
  display: flex;
  background: transparent;
  border: 0;
}
.buy-feehead {
  font-weight: 400;
  font-size: 14px;
  color: var(--textSecondary);
}
.buy-coinflex {
  display: flex;
  margin: 20px 10px 24px;
  justify-content: center;
}
.buy-slider .ant-slider-rail {
  height: 1px;
  background-color: #6269a3;
}
.buy-slider.ant-slider {
  width: 430px;
  margin: 0 auto 28px;
}
.buy-slider .ant-slider-handle {
  width: 22px;
  height: 22px;
  margin-top: -10px;
  background-color: var(--sliderBg);
  border: solid 2px var(--sliderBordr);
}
.buy-slider .ant-slider-dot {
  display: none;
}
.ant-slider-mark-text {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: var(--textWhite);
}
.ant-slider-track {
  height: 0;
  background: transparent;
}
.buy-coinbg.active {
  background: var(--textWhite);
  box-shadow: 0px 2px 4px rgb(29 29 29 / 66%);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  background-position: -103px -459.5px;
}
.buy-coinbg span {
  margin-top: 9px;
}
.buy-coinbg {margin: 0 4px;background: transparent;box-shadow: 0px 2px 4px rgb(29 29 29 / 66%);width: 38px;height: 38px;border-radius: 50%;text-align: center;}
.icon.sm.down-angle {
  background-position: -221px -117px;
  transform: rotate(180deg);
}
.circle-border {
  border: 1px solid var(--borderGrey);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  line-height: 26px;
  display: inline-block;
}
.buy-sell-tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.buy-sell-tabs .ant-tabs-nav-list {
  width: 100%;
  margin: 0 16px;
}
.buy-sell-tabs .ant-tabs-tab {
  flex: 1;
  display: block;
  padding: 12px 0 !important;
  font-weight: 500;
  font-size: 20px !important;
  text-align: center;
}
.buy-fee span {
  padding: 0 4px;
}
/* .buy-radio .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .buy-radio .ant-radio-button-checked{background: var(--btnGrad1);background-color: var(--btnGrad1);color: var(--bgWhite);border-color: var(--btnGrad1);}
.buy-radio .ant-radio-button-wrapper{color: var(--btnGrad1);border-color: var(--btnGrad1);}
.buy-radio{text-align: center;margin-bottom: 30px;} */
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within,
.ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}
.ant-modal-header {
  border-radius: 8px 8px 0 0;
  padding: 16px;
}
.ant-modal-body {
  padding: 0;
  max-height: 480px;
  overflow-y: auto;
}
.site-page-header {
  border-radius: 8px;
}
/* buy/sell component styles end */

/* right panel start*/
.panel-balhead {
  font-weight: 600;
  color: var(--bgWhite);
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 0;
}
.panel-subhead {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--textSecondary);
}
.panel-balcoin {
  font-weight: 300;
  font-size: 26px;
  line-height: 31px;
  color: var(--textCoin);
}
.coin-droupdown
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  height: 44px !important;
  line-height: 32px;
}
.coin-droupdown .ant-select-selection-item p {
  font-size: 26px !important;
}
.coin-droupdown .ant-select-selection-item .ant-typography {
  display: none;
}

/* btn */
.btn-style.ant-btn {
  transition: 0.5s;
  background-size: 200% auto !important;
  font-size: 18px;
}
.btn-style.ant-btn:hover {
  background-position: right center !important;
  background-image: linear-gradient(
    to right,
    #2c00aa 0%,
    #6613ba 50%,
    #2c00aa 100%
  ) !important;
}
.btn-style.ant-btn:hover,
.btn-style.ant-btn:focus,
.btn-style.ant-btn:active {
  background-image: linear-gradient(
    to right,
    #2c00aa 0%,
    #6613ba 50%,
    #2c00aa 100%
  ) !important;
  color: var(--textWhite);
}
.coinBal-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  position: relative;
}
.coinbal-tabs {
  color: var(--bgWhite);
  background: #212646;
  border-radius: 29px;
  padding: 8px;
  height: 50px;
  align-items: center;
  display: flex;
}
.tabs-button {
  font-size: 20px;
  font-weight: 600;
  background: transparent;
  border: 0;
  color: var(--bgWhite);
  padding: 10px 40px;
  line-height: 3px;
  height: 36px;
  border-radius: 42px;
}
.tabs-button.btn-active,
.ant-btn.tabs-button:hover,
.head-button:hover {
  background: linear-gradient(89.35deg, #6613ba 31.31%, #2c00aa 94.88%);
  border-radius: 42px;
  color: var(--bgWhite);
}
.card-align {
  margin: 10px 52px;
}
.head-button:hover {
  border: 1px solid transparent;
}

.orderhistory .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.orderhistory.ant-collapse {
  border: 0 !important;
  background-color: transparent;
}
.orderhistory.ant-collapse > .ant-collapse-item {
  border-bottom: 0 !important;
}
.orderhistory .ant-collapse-header {
  padding: 12px 0 12px 8px !important;
}
.status-color.Cancelled {
  color: var(--textCancelled);
}
.status-color.Success {
  color: #00b93f;
}
.status-color.Pending {
  color: var(--textPending);
}
.orderhistory .ant-collapse-content {
  background-color: transparent !important;
}
.orderhistory .order-item {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 10px 12px !important;
  margin-bottom: 0;
}
.orderhistory .order-item:hover {
  background-color: var(--bgLightBlue);
}
.orderhistory .ant-list-item-meta-title {
  margin-bottom: 0;
}
.orderhistory .ant-list-item-meta {
  align-items: inherit !important;
}
.history-data:not(:last-child) {
  min-width: 100px;
  margin-right: 12px;
}
.panel-card {
  background-color: var(--bgLightGray);
  border: 1px solid var(--borderGrey);
  border-radius: 8px;
  margin: auto;
}
.cust-input {
  background: var(--bgCard) !important;
  border: 1px solid var(--borderGrey) !important;
  border-radius: 25px !important;
  margin-bottom: 20px;
  width: 100%;
  font-size: 14px;
  height: 44px;
  overflow: hidden;
  margin-top: 3px;
}
.card-lines {
  margin: 10px 0;
  border-bottom: 1px solid var(--borderGrey);
  border-top: 1px solid var(--borderGrey);
  padding: 12px 0;
}
.cust-input .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
}
.ant-select-selector {
  border: none !important;
}
.ant-select.cust-input {
  margin-bottom: 15px;
}
.custom-tabs .ant-tabs-tab {
  padding-top: 0 !important;
  color: var(--textSecondary);
  font-size: 16px;
  font-weight: 500;
}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--textBlue);
}
.custom-tabs.new-custom-tab {
  width: 100%;
}
.new-custom-tab .ant-tabs-nav {
  width: 100%;
}
.ant-tabs-ink-bar {
  height: 3px !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: var(--textBlue);
}
.coin-search-input,
.coin-search-input .ant-input-group-addon {
  background: var(--bgCard);
  border-radius: 25px;
  cursor: pointer !important;
}
.address-search .ant-input-affix-wrapper {
  background: #1b182e;
  border-radius: 25px !important;
  cursor: pointer !important;
}
.coin-search-input .ant-input-group {
  height: 44px;
  line-height: 40px;
}
.coin-search-input .ant-input-search-button {
  border: transparent !important;
}
.coin-search-input .ant-btn {
  background-color: transparent;
  box-shadow: none;
}
.coin-search-input .ant-input-affix-wrapper-borderless {
  padding: 12px 20px;
}
.coin-search {
  margin-bottom: 20px;
  cursor: pointer;
}
.dt-topspace {
  margin-top: 20px;
}
.coin-search .ant-input-group-addon {
  display: none;
}
.coin-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--textWhite);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.coin-fullname {
  font-weight: 400;
  font-size: 12px;
  color: var(--textWhite);
}
.right-text {
  font-weight: 400;
  font-size: 14px;
  color: var(--textWhite);
}
.ant-modal-content,
.ant-modal-header,
.ant-modal-footer {
  background: #131024;
  color: var(--textWhite);
  border: 0;
}
.coin-item {
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 12px;
}
.coin-item .ant-list-item-meta-content {
  text-align: left;
}
.coin-item .ant-list-item-meta-title {
  margin-bottom: 0;
  line-height: 18px;
}
.coin-item .ant-list-item-meta {
  align-items: flex-start !important;
}
.coin-item .ant-list-item-action {
  margin-left: 34px !important;
}
.coin-item .ant-list-item-action li {
  padding: 0;
}
.custom-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.card-head {
  margin-bottom: 0 !important;vertical-align: middle;
}
/*Withdraw*/
.summary-list-item.fiat-summary {
  border-bottom: 0 !important;
  padding: 20px 0;
}
.steps-icon {
  width: 36px;
  height: 36px;
  background-color: var(--bgWhite);
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
}
.custom-steps .ant-steps-item-container {
  display: flex;
  align-items: center;
}
.custom-steps .ant-steps-item {
  opacity: 0.5;
}
.custom-steps .ant-steps-item.ant-steps-item-active {
  opacity: 1;
}

.copy-btn {
  background-color: var(--textBlue) !important;
  color: var(--textWhite) !important;
  font-weight: 700;
  font-size: 12px;
  border-radius: 18px;
  text-transform: uppercase;
  padding: 7px 15px;
}
.cust-qr {
  margin: 60px 0;
}
.copy-btn:hover,
.copy-btn:active {
  background: var(--textBlue) !important;
  color: var(--bgWhite) !important;
}
.ant-popover-inner {
  border-radius: 7px;
}
.btn-addon,
.btn-addon:hover,
.btn-addon:active {
  background-color: var(--bgBlueLight);
  color: var(--textPrimary);
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mm-btn .ant-radio-button-wrapper {
  padding: 0 20px;
  font-weight: 500;
  height: 28px;
  line-height: 26px;
}
.mm-btn .ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.mm-btn .ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.mm-btn .ant-radio-button-wrapper-checked {
  background-color: var(--bgBlue) !important;
}
.text-grey {
  color: var(--textSecondary);
}
.text-primary {
  color: var(--textPrimary);
}
.cust-input.cust-code-input .ant-input {
  background-color: transparent;
  border: transparent;
  padding: 10px;
}
.cust-input.cust-code-input .ant-input-group-addon {
  background-color: var(--bgBlueLight);
  border: 1px solid #bdbdbd;
  padding: 10px;
}
.text-blue {
  color: var(--textBlue);
}
.summary-list-item:last-child {
  margin-bottom: 0;
}
.ant-steps-item-custom:last-child .ant-steps-item-container {
  display: none;
}
.ant-steps-item-custom:nth-last-child(2) .ant-steps-item-title::after {
  content: none;
}
.custom-label .ant-form-item-label,
.custom-label.ant-row {
  display: block !important;
  text-align: left;
}
.pannel-bg {
  background: #040015;
  border: 1px solid #204483;
  box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15);
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
.bg-image {
  position: absolute;
  right: -2px;
}
.pay-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.enter-val-container .text-yellow {
  font-size: 22px !important;
}
.text-red-colr {
  color: #ff5e43 !important;
  font-weight: 500 !important;
}
.custom-add-select {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.new-add .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  border-color: transparent !important;
  outline: none !important;
}
.get-code {
  width: 100%;
  text-align: center;
  height: 50px;
  border: 1px solid var(--borderLight);
  color: var(--textWhite30);
  font-size: 16px;
  padding: 0 16px !important;
}
.hy-align .ant-btn-loading::before {
  background-color: transparent !important;
}
.hy-align {
  margin: auto 0;
  display: flex;
  align-items: center;
  border-left: transparent !important;
}
.otp-verify {
  position: relative !important;
  margin-left: 0 !important;
}
.otp-verify button {
  color: var(--textYellow) !important;
  font-weight: 500;
  padding: 0 6px 0 0;
}
.otp-verify .ant-form-item-label {
  width: auto;
  position: absolute;
  right: 4px;
  top: 10px;
  z-index: 1;
}
.otp-verify .ant-form-item-label > label::after {
  content: none !important;
}
.input-label.otp-verify .ant-form-item-control {
  margin-top: 4px;
}
.cust-border {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  height: 52px;
}
.cust-right-border {
  font-size: 18px;
  line-height: 50px;
  text-align: left;
  border: 1px solid #6269a3;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #2c2653;
}
.c-notallowed {
  cursor: not-allowed;
}
.payees-inpt-fltr {
  justify-content: space-between;
}
.payees-panel table > thead > tr .ant-table-cell {
  color: var(--textSecondary);
  font-size: 14px;
  font-weight: 400;
}
.payees-panel table > tbody > tr > td {
  color: #5a575c;
  font-size: 14px;
  font-weight: 400;
}
.payees-panel table tr {
  border-bottom: 1px solid var(--borderGrey) !important;
}
.usd-card {
  background-color: var(--bgLightGray);
  border: 1px solid var(--borderGrey);
  border-radius: 8px;
  margin: auto;
  padding: 16px;
}
.paye-inpt .ant-input-borderless {
  padding-top: 9px;
}
.payee-search {
  width: 277px;
  font-size: 16px;
  font-weight: 300;
}
.payee-search .ant-btn.ant-btn-icon-only {
  border-left: 1px solid var(--borderGrey) !important;
}
.search-sect {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cust-sub-amt {
  font-size: 20px;
  font-weight: 500;
  color: var(--textSecondary);
  line-height: 23.8px;
}

.coin-item .ant-list-item-meta-avatar {
  margin-right: 12px;
  margin-top: 5px;
}
.coin-val {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: var(--textPrimary);
}
.coin-price {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: var(--textSecondary);
}
.coin-fillname {
  font-size: 12px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--textSecondary);
}
.coin-item.active .coin-fillname {
  color: var(--textWhite);
}
.coin-item.active .coin-price {
  color: var(--textWhite);
}
.coin-flexgrow {
  display: flex;
}
.coin-flexgrow .ant-list-item-meta {
  flex: 1;
}
.coin-flexgrow .d-coinlist {
  flex-grow: 1;
  flex-basis: 0;
}
.coin-flexgrow .coin-li {
  flex: 150px 0 0;
  margin-left: 8px;
}
.deposit-subheading {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: var(--textSecondary) !important;
  line-height: 21.48px !important;
}
.user-name {
  text-align: left;
  color: var(--textWhite);
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
}
.account-name {
  text-align: left;
  color: var(--textWhite);
  font-weight: 300;
  font-size: 12px;
  line-height: normal;
}
.btn-group .small-btn {
  height: 28px;
  width: 56px;
}
.btn-group .min {
  border-radius: 5px 0 0 5px !important;
}
.btn-group .max {
  border-radius: 0 5px 5px 0px !important;
}
.btn-group .small-btn:hover {
  background-color: var(--bgBlue);
}
.btn-group .small-btn span {
  font-size: 14px;
  line-height: 14px !important;
  color: var(--textBlue);
  font-weight: 500;
}
.btn-group .small-btn:hover span {
  color: var(--textWhite) !important;
}
.order-lbl {
  font-size: 10px;
  font-weight: 300;
  color: var(--textSecondary);
  margin-bottom: 4px;
}
.summary.panel-card.buy-card.card-paddingrm .alert-flex {
  top: -220px !important;
}

/*deposit---start*/
.panel-card.text-center {
  padding: 30px;
}
.panel-card.text-center .buy-token {
  margin-bottom: 20px;
}
.selectbank-list .bank-listhead,
.bank-totalammount {
  color: var(--textWhite);
}
.bank-listhead {
  display: flex;
  align-items: center;
}
.bank-listhead .crypto {
  margin-right: 12px;
}
.selectbank-list .ant-list-item-meta {
  /* flex: 230px 0; */
}
.dt-bank .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
}
.dt-benefiary {
  background-color: var(--bgCard);
  border-radius: 20px;
  padding: 35px 20px;
}
.input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: var(--textSecondary);
}
.input-value {
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  color: var(--textPrimary);
  text-align: right;
  margin-bottom: 0 !important;
}
.dt-selectext {
  font-weight: 500;
  font-size: 14px;
  color: #a8a8a8;
  margin: 15px 0 10px 0px !important;
  text-align: left;
}
.dt-input-title {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px;
  color: var(--textPrimary) !important;
  margin: 34px 0 4px !important;
  text-align: left;
}
.imp-title {
  font-size: 18px;
  font-weight: 600px;
  line-height: 20px;
  color: var(--textPrimary);
}
.imp-note {
  font-size: 14px;
  font-weight: 500px;
  line-height: 20px;
  color: var(--textSecondary);
  margin-top: 15px;
}
.dt-imp-block {
  margin: 0 50px;
}
.dt-text {
  font-size: 14px;
  font-weight: 300;
  color: var(--textSecondary);
  margin-bottom: 24px;
}
.dt-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.dt-flex.btn-primary {
  padding: 0 35px;
}
.dt-bank-ml .bank {
  margin-left: 53px;
}
.buy-coinselect.dt-bank-ml {
  padding: 8px;
}
.dt-mb {
  margin-bottom: 20px;
}
.dt-flex .input-label {
  width: 230px;
}
.agree-check .agree-text {
  padding-left: 0;
}
.copy-code {
  font-size: 24px;
  font-weight: 700;
  color: var(--textPrimary);
}
.total-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  padding: 16px;
}
.total-card div.ant-typography {
  margin-bottom: 0px !important;
}
.deposit-select .cust-input .ant-select-selector {
  background: var(--bgCard);
  color: var(--textSecondary);
}
.deposit-select .cust-input .ant-select-arrow {
  color: #1890ff;
  padding-right: 20px;
}
.note-content {
  font-size: 14px;
  font-weight: 500 !important;
  color: var(--textSecondary);
  margin-top: 16px;
}
.select-bank {
  padding-left: 12px;
}
.deposit-select .cust-input {
  padding: 0px 10px 0px;
}
.deposit-fait .ant-divider-horizontal {
  margin: 0px !important;
}
.coins-header {
  padding: 0px 20px;
}
.note-header {
  margin-bottom: 20px !important;
}
.fait-content {
  padding: 0px 12px;
}
.ordrhstry-title {
  text-transform: capitalize !important;
}
.order-hist-margin {
  margin-bottom: 8px;margin-top: 0;
}
.icon-up {
  margin-left: 5px;
  margin-top: 7px;
}
.icon-down {
  margin-left: 5px;
}
.coin-margin {
  margin-left: 12px;
}
.dashboard-transactions .order-hist-margin {
  margin-bottom: 14px;
}
/*deposit---end*/

/* deposit crypto start */
.currency-panel {
  margin-left: 16px;
}
.deposit-crypto .sm-card-title {
  text-transform: none !important;
  text-align: center !important;
}
.qr-img {
  margin: 36px 0px;
  width: 180px;
  height: 175.58px;
}
.smm-icons {
  margin: 12px 0px;
}
.smm-icons .icon {
  margin: 0px 6px;
}
.deposit-crypto .summaryList-total {
  width: 100%;
}
.deposit-right-panel .coinBal-flex {
  padding-left: 4px !important;
}
.deposit-crypto .check-boxalignment {
  margin-top: 15px !important;
}
.deposit-crypto .agree-text {
  margin-left: 8px !important;
  padding-left: 0px !important;
}
.deposit-crypto .ant-tabs-content {
  display: inherit !important;
}
.deposit-crypto .qr-image {
  margin: 30px 0px 30px;
  background: #ffffff;
  padding: 10px;
}
.deposit-crypto .shareicon {
  border-radius: 50%;
}
.deposit-crypto .coinBal-flex {
  padding-left: 0px !important;
}
/* deposit crypto end */

/* dashboard---start */
/* .leftcard{height: 371px;} */
.marketcap-coinsize .ant-table-body {
  overflow-y: hidden !important;
}
.right-bgpannel {
  height: 288px;
}
.db-quicklinks {
  background: linear-gradient(
    84.34deg,
    #6613ba 41.55%,
    #2d00aa 96.49%,
    #2d00aa 96.49%,
    #2c00aa 96.49%
  );
  border: 1px solid #204483;
  box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15);
  border-radius: 20px;
  padding: 16px 16px 23px 16px;
  margin-bottom: 16px;
}
.icon-circle {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(241, 241, 241, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.03);
}
.customicon {
  display: inline-block;
}
.customicon:hover .icon-circle {
  background: #f1f1f1 !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.03);
}
.customicon:hover h5 {
  color: var(--textPrimary);
  font-size: 16px;
  font-weight: 500;
}
h5.text-hover {
  color: var(--textPrimary);
  font-size: 16px;
  font-weight: 500;
}
.db-faitcard {
  background: #131024;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 10px 16px;
}
.db-faitcard h3 {
  margin-bottom: 0 !important;
}
.db-faitcard-bal {
  font-size: 22px;
  font-weight: 500 !important;
  color: var(--textWhite) !important;
}
.db-fat-dollar {
  font-weight: 500;
  color: var(--textSecondary);
  font-size: 20px;
}
.db-faitcard-total {
  font-weight: 500;
  font-size: 16px;
  color: var(--placeholderText);
}
.ant-list-split .ant-list-item {
  border: none !important;
  cursor: pointer;
}
.custom-flex.ql-mb {
  margin-bottom: 24px;
}
.ant-divider {
  border-top: 1px solid #393e64;
}
.db-fait {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fait-invalue,
.crypto-invalue {
  margin: 0 !important;
}
.fait-invalue span {
  color: var(--textGreen);
  font-size: 14px;
  font-weight: 400;
}
.crypto-invalue span {
  color: var(--textError);
  font-size: 14px;
  font-weight: 400;
}
.db-hw {
  font-weight: 300 !important;
  font-size: 30px !important;
  color: #a8a8a8 !important;
}
.db-hw span {
  font-size: 40px;
  font-weight: 600;
  color: var(--textPrimary);
}
.db-hw-text {
  font-weight: 400;
  font-size: 16px;
  color: var(--placeholderText);
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.db-hw-text-full{ font-weight: 400; font-size: 16px; color: var(--placeholderText);}
.ant-tooltip-inner{    background: #040015;
  border: 1px solid #204483; box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15); border-radius: 20px;  padding: 10px;}
/* faitwallet */
.coin-item {
  background: #131024;
}
.ant-list-items .coin-item:hover,
.ant-list-items .coin-item:focus,
.ant-list-items .coin-item:active {
  background: #2c2653;
}
.coin-item.active {
  background: #2c2653 !important;
}
.db-crypto-link {
  font-size: 14px;
  color: var(--textLink);
  font-weight: 500;
}
.dashb-btn .btn-style {
  width: 100% !important;
}
.quickpay-carsl {
  display: flex;
  justify-content: space-between;
}
.db-portfolio {
  padding: 16px !important;
  height: 371px;
}
.fait-title {
  margin-bottom: 8px;
}
.card-title.db-hw {
  margin-bottom: 3px;
}
.right-bgpannel .ant-divider-horizontal {
  margin: 12px 0 8px;
}
.bank-shortcode {
  width: 26px;
  height: 26px;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  border: 1px solid #666666;
  margin-right: 6px;
}
.custom-modal .dt-topspace .ant-list-items .coin-item {
  min-height: 54px;
}
.left-panel.pannel-bg.mb-leftpanel.leftcard .ant-list-items {
  height: 240px;
  overflow: auto;
}
.left-panel.pannel-bg.mb-leftpanel.leftcard.crypto-wallet .ant-list-items {
  overflow: unset !important;
}
.login-user,
.side-drawer .side-drawer-header,
.side-drawer-full .side-drawer-header,
.wire-trans,
.crypto-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.linktitle-space {
  margin-right: 10px;
}
.db-coinlist .ant-list-item-meta {
  align-items: center !important;
}
.dashboard-content .db-coinlist {cursor: default ;}
/* .db-faitwallet .coinval-width {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.db-faitwallet .ant-list-item-action, .crypto-wallet .ant-list-item-action{
  margin-left: 0px !important;
}
/* .db-faitwallet .coin-flexgrow .d-coinlist {
  flex: none !important;
} */
.crypto-wallet .coinval-width,.db-faitwallet .coinval-width {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.secureDropdown .ant-menu-root {
  background-color: var(--bgCard);
  border-radius: 10px;
  min-width: 180px;
  max-width: 180px;
  padding: 6px 0;
  color: var(--textWhite);
  position: absolute;
  max-height: calc(100vh - 75px);
  overflow-y: auto;
  border: 0;top: 27px !important;
}

.depwith-drpdown .ant-dropdown-menu {
  width: 100px !important;
  max-width: 100px !important;
  background: #242038;
  border-radius: 8px;
}
.dropdown-list {
  padding: 0px;
  color: var(--textWhite);
  text-align: left;
  margin: 10px 2px;
  list-style: none;
  background: var(--bgLightGray);
}
.dropdown-list li:hover {
  background: #2c2841;
}
.dropdown-list li {
  padding: 4px 16px;
}
.dropdown-list a {
  color: var(--textPrimary);padding: 4px 16px;
}
.dropdown-list a:hover {
  background: #2c2841;
}
.dropdown-list .customicon{width: 100%;text-align: left !important;}
/* dashboard----end */

/* summary start */
.rightpanel-card-bg {
  background-color: var(--bgCard);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 30px 0px;
  padding: 36px 10px;
}
.payee-inblock .summary-list-item {
  padding-bottom: 20px;
  border: 1px solid var(--inputBorder);
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;height: 56px;
  margin-bottom: 26px;
}
.summary-list-item {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--inputBorder);
  padding-top: 20px;
}
.summary-container .summary-list .summary-list-item {
  border-bottom: 1px solid var(--inputBorder) !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.cryptocoin-amount {
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color: var(--textWhite) !important;
  margin-bottom: 0 !important;
}
.cryptocoin-amount span {
  font-size: 26px !important;
  font-weight: 300;
  color: #a8a8a8 !important;
}
.currencycoin-amount {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #99999a !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.sm-card-title {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 36px !important;
  color: #fff !important;
  text-align: left;vertical-align: middle;
}
.summary-list-item.summaryList-total {
  background: #282344;
  border-radius: 12px;
  border: 1px solid var(--borderPanel);
  padding: 16px;margin-bottom: 10px
}
.note-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a8a8a8;
  padding: 0px 20px;
}
.note-heading {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px;
  color: var(--textPrimary) !important;
}
.btn-primary.ant-btn span {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: var(--textWhite);
  text-transform: uppercase;
}
.ant-btn-block.btn-primary::after {
  background-color: transparent;
}
.ant-btn-loading::before {
  background-color: transparent;
}
.ant-btn-loading-icon {
  text-align: left;
}
.confirm-btntext {
  font-weight: 600 !important;
  line-height: 36px !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.confirm-lefticon {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.confirm-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ant-checkbox-inner {
  background: transparent;
  border: 1px solid #6613ba;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: linear-gradient(89.35deg, #6613ba 31.31%, #2c00aa 94.88%);
  border: 1px solid #6613ba;
}
.ant-checkbox-checked::after {
  border: 1px solid #6613ba;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background: linear-gradient(89.35deg, #6613ba 31.31%, #2c00aa 94.88%);
  border: 1px solid #6613ba;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #6613ba;
}
.checkbox-red .ant-checkbox-inner,.checkbox-red .ant-checkbox-inner:hover {
  border: 1px solid #ff4d4f !important;
}
h1.check-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8a8a8a;
  margin-left: 8px;
}
.ant-btn-loading {
  display: flex;
}
.check-boxalignment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
}
.ant-alert-with-description {
  padding: 6px; align-items: center;
}
.sm-crypto-bal {
  margin-left: 20px;
}
.summary.rightpanel-card-bg .sm-card-title {
  text-align: left;
  padding-left: 28px;
}
.coinBal-flex.summary-page {
  padding: 0px 30px;
}
.withdraw-hesderbal {
  margin-left: 16px !important;
}
/* summary end */
/* selection-pop start */
/* .custom-modal {
  width: 650px;
} */
.ant-modal-close-icon {
  display: none;
}
.custom-modal .coin-container .coin-search-input {
  background: var(--bgDark);
}
.custom-modal .ant-list-items .coin-item,
.custom-modal .coin-item {
  background: var(--bgDark);
  border: 1px solid #204483 !important;
  box-shadow: 0px 8px 10px rgba(57, 126, 246, 0.15);
  border-radius: 12px;
}
.custom-modal .ant-list-items .coin-item.active,
.coin-item.active {
  background-color: #2c2653;
}
.wd-search .coin-search-input {
  background: var(--bgDark);
  width: 460px;
  margin: 0 auto;
}
.wd-search .coin-search {
  text-align: center;
}
.wd-search .coin-item.wd-addressbook {
  background: #040015;
  border: 1px solid #204483 !important;
  box-shadow: 0px 8px 10px rgb(57 126 246 / 15%);
  border-radius: 12px;
}
.coin-item.wd-addressbook:hover,
.ant-list-items .coin-item.wd-addressbook:focus,
.ant-list-items .coin-item.wd-addressbook:active {
  background: #2c2653;
}
.custom-modal .ant-modal-content {
  padding: 20px 10px;border-radius: 8px;
}
/* selection-pop end */
/* table */
.custom-table .td-success {
  color: var(--textGreen) !important;
}
.custom-table .td-pending {
  color: var(--textPending) !important;
}
.custom-table .td-cancelled {
  color: var(--textCancelled) !important;
}
.td-success {
  color: var(--textGreen) !important;
}
.td-pending {
  color: var(--textPending) !important;
}
.td-cancelled {
  color: var(--textCancelled) !important;
}
.coin-title.td-red{color: rgb(255, 0, 0);}
.custom-table .ant-table-thead > tr > th {
  background: transparent;
  color: var(--textSecondary);
  font-weight: 300;
  font-size: 12px;
}
.custom-table .ant-table-thead > tr > th::before {
  content: none !important;
}
.custom-table .ant-table {
  background-color: transparent;
  overflow: scroll;
}
.custom-table .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent;
}
.custom-table .ant-table-tbody > tr > td {
  color: var(--textPrimary);
}
.custom-table .table-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--placeholderText);
}
.custom-table h4.table-text {
  font-size: 16px;
  color: var(--textPrimary);
  font-weight: 400;
  margin-bottom: 0 !important;
  padding-right: 16px;
}
.custom-table .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #393e64;
  border-top: 1px solid #393e64;
}
.custom-table .ant-table .ant-table-tbody tr:last-child td {
  border-bottom: 0;
  border-top: 0;
}
.mb-width {
  width: 145px;
}
.mb-width-high {
  width: 200px;
}
.buy-crads-data {
  width: auto !important;
}
/* table */
/* buy  */
.buysome-amt {
  margin: 20px 0px;
}
.buy-max {
  background-color: transparent !important;
  color: #fff;
  border: none;
  margin-top: 2px;
}
.buy-max:hover {
  border-bottom: 1px solid #397ef6 !important;
  color: #397ef6;
}
.buy-max span {
  font-weight: 700;
  font-size: 18px;
}
.buy-max.ant-btn:active,
.buy-max.ant-btn:focus {
  color: #397ef6 !important;
}
.buy-max.ant-btn {
  outline: none;
}
/* buy  */
/* thank you screen start */
.thank-btn {
  margin: 0px auto;
  margin-top: -28px;
}
.thank-btn1 {
  margin: 0px auto;
  margin-top: 26px;
}
.thankyou-main {
  background-color: #131024;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  padding: 60px;
  background-image: url(../images/Group.png) !important;
  background-position: top center;
  background-repeat: no-repeat;
}
.thank-header {
  font-size: 26px;
  font-weight: 600;
  color: white !important;
}
.thank-currency {
  font-weight: 500;
  font-size: 18px;
  color: var(--textSecondary);
}
.currency-right {
  margin-left: 20px;
}
/* thank you screen end */
/* WITHDRAW i want to withdraw START */
.wd-btn {
  margin-top: 200px;
}
.wd-entry {
  border-bottom: 1px solid #6269a3 !important;
}
.wd-search .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}
.wd-search .cust-input {
  border-radius: 0 !important;
}
.wd-search .ant-input {
  color: var(--textCoin);
  font-size: 14px;
  font-weight: 500;border: 1px solid #2c2841;
}
.wd-search {
  width: 450px;
  margin: 0 auto;
}
.buy-selecttoken.wd-transfer {
  height: 50px;
}
.wd-search textarea {
  height: 70px;
}
.total {
  text-align: center;
}
.card-paddingrm .ant-form-item-explain-error {
  width: fit-content;
  margin: -35px 0px 35px 110px;
}
/* WITHDRAW i want to withdraw END */
/* withdraw Agree start */
.agree-title {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #f1f1f1 !important;
  text-transform: uppercase !important;
}
.custom-label label {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #a8a8a8;
}
.agree-main {
  width: 465px;
}
.agree-input {
  background: #040015 !important;
  margin: 0px !important;
  height: 53px;
}
.agree-input::placeholder {
  font-size: 16px;
  font-weight: 400px;
  padding-left: 30px;
}
.agree-row .ant-col {
  border-bottom: 1px solid #2c2841;
  padding: 16px;
}
.dt-benefiary label {
  margin-left: 10px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 51px !important;
}
.agree-input .ant-select-selection-item {
  font-size: 16px;
  font-weight: 400px;
  margin: 10px 0px 0px 10px;
  color: #a8a8a8;
}
.agree-drpdwn {
  margin: 36px auto;
}
.agree-input .ant-select-selector {
  width: 310px !important;
}
.drpdwn-content {
  margin-bottom: 20px;
}
.agree-container {
  padding: 0px 130px;
}
.summary {
  background-color: var(--bgCard);
  border-radius: 20px;
  padding: 30px 0px;
  margin: 30px 0px;
}
.agree-header {
  margin: 30px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
}
.details .ant-col {
  border-bottom: 1px solid #2c2841;
}
.agree-details {
  margin: 10px 0px 20px 0px !important;
}
.wd-search.wd-p .ant-empty.ant-empty-normal {
  background: var(--bgDark);
}
.input-cards input {
  padding: 4px 0px !important;
}
.summary input {
  color: white;
}
.summary.address-margin {
  margin-top: 0;
  padding-top: 5px;
}
/* .bank-details{width:78%;} */
/* withdraw Agree end */
/* withdraw summary screen start */
.summary-lbl {
  font-weight: 400;
  font-size: 14px;
  color: #a8a8a8;
  margin-left: 10px;
}
.summary-input {
  background: var(--bgCard) !important;
  font-size: 18px;
  line-height: 50px;
  height: 50px;
  text-align: left;
  border: 1px solid #6269a3 !important;
  background-color: transparent;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.summary-input::placeholder {
  font-size: 16px;
  font-weight: 400px;
  padding-left: 30px;
}
.agree-container .cust-input input {
  background-color: transparent !important;
  border-right: 1px solid white;
}
.agree-container .getcode-btn {
  border: 1px solid white !important;
}
.agree-container .summary-screen {
  display: none;
}
.bal-title {
  margin: 36px 0px 0px 0px;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 24px;
  color: var(--textPrimary) !important;
  text-transform: capitalize;
  text-align: left;
  vertical-align: middle;
}
.summary-container .summary-screen {
  display: none;
}
.custom-forminput.custom-label {
  margin-bottom: 10px !important;
}
.buy-toamount.wt-verify-code {
  margin: 0;
  position: relative;
  width: auto;
}
.ant-badge-count {
  top: -1px;
  right: 135px;
  background-color: var(--textGreen);
  width: 80px;
}
.wt-verify {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wt-verify .mr {
  margin-right: 10px;
}
.wt-rejected-badge {
  background-color: var(--textError);
  color: var(--bgWhite);
  border-radius: 10px;
  font-size: 11px;
  padding: 4px 15px;
  position: absolute;
  line-height: 11px;
  top: -15px;
  left: 15px;
}

.icon.md.back-arrow {
  background-position: -7px -141px;
  margin: 0px 0px 15px 0px;
}
.icon.md.left-backarrow {
  background-position: -532px -116.5px;
  transition: left 0.3s;
  transform: scale(1.2);
  cursor: pointer;
}
.back-arrow button {
  background-color: #4b4b4c;
  border: none;
  padding: 5px 6px;
  transition: left 0.3s;
  left: -27px;
  top: -19px;
  cursor: pointer;
  border-radius: 10px 0px 0px 10px;
  width: 45px;
  position: absolute;
}
.back-arrow button:hover {
  left: -35px;
  background-color: #4b4b4c;
}
.back-arrow .button:active {
  background-color: #4b4b4c;
}
.back-arrow span:hover {
  left: 5px;
}
.back-arrow {
  position: absolute;
}
.back-arrow button::after {
  box-shadow: none !important;
}
.back-arrow.arrow-align {
  margin-bottom: 3px;
}
/* .back-coin{position: absolute; top: -4px;left: 23px} */
.wd-slider-m {
  margin-top: -12px !important;
}
.send-m {
  margin-top: -16px !important;
}
.wd-addressbook.summary-list-item.summaryList-total {
  width: 100%;
}
.coinbal-tabs button:nth-child(1) {
  margin-right: 5px !important;
}
.withdraw-rightpanel {
  margin-right: 0px !important;
}
.withdraw-summerypanel {
  flex: 1;
  text-align: center;
  padding: 16px !important;
}
.getcode-input {
  align-items: center;
}
.getcode-input .ant-input {
  border-right: 2px solid #5cc500;
  height: 47px;
  margin-right: 5px;
  font-weight: 400 !important ;
  font-size: 24px !important;
}
.get-codeerror .getcode-input .ant-input {
  border-right: 2px solid #af1225 !important;
  height: 47px;
  margin-right: 5px;
  font-weight: 400 !important ;
}
.not-verified {
  background: #af1225;
}
.token-height {
  height: 100px !important;
}
.input-label {
  font-size: 12px !important;
  line-height: 20px;
  color: var(--placeholderText);
  text-transform: uppercase;
}
.label-card {
  left: 13px;
  top: -12px;
  color: var(--textSecondary);
  background-color: #131024;
  padding: 4px 12px;
  cursor: pointer;
  position: absolute;
  font-weight: 400;
  border-radius: 8px;
  z-index: 1;
  text-transform: none;
}
.scan-icon {
  position: absolute;
  top: 15px;
  right: 13px;
}
.cust-verify .cust-input {
  margin-top: 0px;
  height: 50px;
  border-right: none !important;
  border-radius: 0px;
}
.otp-verify::placeholder {
  margin-top: 10px;
  padding-top: 10px !important;
}
.verify-btn {
  color: #fff;font-size: 14px !important;
}
.get-code .c-notallowed,
.get-code .text-yellow {
  color: #fff;
}
.otp-verify .ant-form-item-extra span {
  color: #a9a9a9;
  font-size: 14px;
  text-transform: capitalize;
}
.cust-verify {
  width: 548px;
  margin: 0;
}
/* .summary .summary-list-item{border-bottom: none;} */
.wd-inblock {
  margin-bottom: 30px;
}
/* .otp-verify .icon.md.info{background-position: -40px -231px; transform: scale(0.8); width: 20px;height: 20px;} */
/* withdraw summary screen end */
/* dropdown start */
.ant-select-dropdown {
  background-color: #2c2653;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #131024;
  color: var(--textWhite);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #040015;
  color: var(--textWhite);
}
.ant-select-item {
  color: var(--textWhite);
}
.ant-select-arrow {
  margin-right: 10px;
  color: var(--textWhite);
}
/* dropdown end */
/* radiobtn start */
.ant-radio-inner {
  background: #040015;
  border: 1px solid #204483; width: 24px;
  height: 24px;
}
/* .ant-radio-checked .ant-radio-inner{background: linear-gradient(89.35deg, #6613BA 31.31%, #2C00AA 94.88%);} */
.card-title {
  font-size: 22px !important;
  font-weight: 500 !important;
}
.ant-radio-input:focus + .ant-radio-inner {
  border: 1px solid #204483;
}
.ant-radio-inner::after {
  background: linear-gradient(89.35deg, #6613ba 31.31%, #2c00aa 94.88%);
}

.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.9);
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #204483;
}
.ant-radio-wrapper {
  display: flex;
  align-items: center;
}
.coin-container input {
  color: white;
}
/* radiobtn end */
/* payeee start*/
.payee-inblock,
.summary-panel {
  padding: 12px 0;
  width: 465px;
  margin: auto;
}
hr {
  background-color: #2a2a2a;
  border: none;
  height: 1px;
  width: 465px;
}
.payee-leftdata {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.payee-input {
  font-size: 18px;
  line-height: 35px;
  text-align: left;
  border-bottom: 1px solid #6269a3 !important;
  background-color: transparent;
  border: 0;
  outline: 0;
  width: 100%;
  padding-left: 18px;
  color: white;
}
.payee-coinselect {
  background-color: #131024;
  padding: 0px 6px;
  /* cursor: pointer; */
}
.payee-token {
  background: var(--bgCard);
  border-radius: 8px;
  display: inline-block;
  z-index: 1;
}
span.ant-radio + * {
  display: inline-block;
  margin-top: 5px;
}
.payee-dply {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .payee-summary-bank{margin: 0 auto;width: 531px;} */
.payee-field {
  margin-bottom: 36px;
  padding-right: 8px;
}
.input-bordered {
  font-size: 18px;
  line-height: 50px;
  text-align: left;
  border: 1px solid #6269a3 !important;
  background-color: transparent;
  border-radius: 8px;
}
.p-relative {
  position: relative;
  text-align: center;
}
.p-absolute {
  position: absolute;
  top: -15px;
  left: 7px;
  font-weight: 400;
}
.payee-leftdata.coin-item {
  border: 0 !important;
  cursor: pointer;
}
.payee-favname {
  width: 90px;
  word-break: break-word;
}
.lable-position {
  position: absolute;
  background: #131024;
  top: -12px;
  padding: 0px 6px;
}
.payee-inblock .summary-text {
  width: 100%;
  text-align: left;
}
.payee-inblock .bal-title {
  margin-top: 10px;
  margin-bottom: 35px;
}
.addon-icon {
  display: flex;
  justify-content: end;
  gap: 20px;
}
.payee-field .ant-select-selector {
  align-items: center;
  display: flex;
}
.icon.md.close-white {
  background-position: -342px -179px;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.btn-style.payee-popup {
  height: 40px !important;
  width: 100px !important;
  text-align: center !important;
  display: inline-flex !important;
  justify-content: center;
  padding: 8px 24px;
  margin-top: 0;
}
.btn-style.payee-popup span {
  font-size: 18px !important;
}
.disable-pop {
  left: 168px;
}
.disable-pop .ant-modal-content {
  padding-bottom: 10px;
}
.outlined-btn {
  font-size: 18px;
  background-color: transparent;
  color: #fff;
  border-radius: 47px;
  border: 1px solid #204483;
  width: 100px;
  height: 43px;
  text-transform: uppercase;
}
.outlined-btn:hover {
  border-color: #144baa;
  background-color: #242038;
}
.icon.lg.payee-back-arrow {
  background-position: 0 -141px;
}
/* payeee end */
/* transaction start */
.custom-table.card {
  background-color: var(--bgCard);
  padding: 10px 20px;
  align-items: start;
  border-radius: 10px;
  margin-bottom: 8px;
  align-content: stretch;
}
.custom-table .table-date {
  margin: 0px 30px;
}
.btn-style.card-btn span {
  font-size: 18px !important;
  margin-left: 17px;
  margin-top: 3px;
}
.flex-width {
  flex: 0 0 120px;
}
.table-grid {
  width: 100%;
  /* overflow: auto; */
  margin-bottom: 12px;
}
.grid-search {
  width: 170px;
  margin-bottom: 16px !important;
}
.grid-search input {
  color: white;
}
.grid-transaction {
  margin-right: 15px;
}
.order-title h1 {
  margin-bottom: 0 !important;
}
.rec-details {
  padding-bottom: 7px;
}
.custom-table.card:last-child {
  margin-bottom: 0 !important;
}
.dashb-transactionsicon {
  margin-bottom: 4px;
}
.transaction-subtext {
  margin: 0px 0px 10px;
  color: var(--textSecondary);
  font-size: 16px;
  display: inline-block;
}
.transactions-header .grid-search {
  margin-bottom: 0px !important;
}
.transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: start !important;
}
.transactions-header .filter-icon {
  margin-top: 5px;
}
.transactions-totalgrid .ant-row:last-child .custom-table.card {
  margin-bottom: 0 !important;
}
/* transactions end */
/* summary new */
.earn-card h2 {
  font-size: 24px !important;
  font-weight: 500;
  color: var(--textPrimary) !important;
  margin-bottom: 0px;
}
.flip-icon .icon.xlg.flip {
  position: absolute;
  top: 27px;
  right: -14px;
  transform: translate(0px, -15px);
}
.icon.xlg.flip {
  height: 57px;
  width: 39px;
  background-position: -69.5px -683.5px;
}
.icon.xlg.flip.substract {
  height: 57px;
  width: 39px;
  background-position: -253px -683.5px;
}
/* .icon.xlg.flip:hover{height: 57px;width: 39px;background-position: -69.5px -748px;} */
.icon.xlg.code-verified {
  height: 40px;
  width: 47px;
  background-position: -186.5px -696.5px;
}
.icon.xlg.code-notverified {
  height: 40px;
  width: 47px;
  background-position: -136.5px -696.5px;
}
.flip-icon {
  position: relative;
  width: 11px;
}
.cust-cards {
  margin: 20px 0px;
  justify-content: center;
  display: block;
}
.earn-card {
  min-height: 98px;
  border: 1px solid var(--borderPanel);
  border-radius: 12px;
  width: 258px;
  padding: 16px;
}
.right-card-ml .input-label,
.right-card-ml .title {
  margin-left: 12px;
}
.summery-panelcard {
  margin-top: 8px;
}
.cust-cards h1.ant-typography {
  color: var(--textPrimary);
  font-size: 24px;
  font-weight: 500;
}
/* summary new end */
/* orderhistory start */
.order-hstry h4 {
  width: 120px;
}
.ant-modal-content {
  left: 0px !important;
}
.exchange-rate {
  margin: 15px 0px;
}
/* orderhistory end */
/* withdraw crypto start */
.text-left select {
  height: 55px;
}
.crypto-agree
  .text-left
  .ant-select-selector
  .ant-select-selection-placeholder {
  margin-top: 10px !important;
}
.crypto-agree .text-left .ant-select-selector .ant-select-selection-item {
  margin-top: 10px !important;
}
.agree-head {
  padding: 0 30px;
}
.summary-list.crypto-agree .payee-input {
  padding: 0 !important;
}
.coinbal-tabs.pop-tabs button {
  flex: 1;
}
.btn-over {
  position: sticky;
  top: -1px;
  z-index: 999;
  background: #131024;
  height: 55px;
}
.custom-modal .dt-topspace .ant-list-items .coin-item.address-card {
  min-height: 80px;
}
.bank-totalammount .address-title {
  color: #ffffff !important;
  font-size: 18px !important;
}
.add-label {
  font-size: 16px;
}
.crypto-agree .buy-entryinput::placeholder {
  text-align: left;
  padding-left: 10px !important;
}
.crypto-agree .ant-select-selector {
  text-align: left !important;
}
.crypto.coin.sm.sgd {
  background-position: -534px -533px;
}
.mr-8 {
  margin-right: 8px;
}
.crypto-addressbook .coin-item .ant-list-item-meta-content {
  /* width: auto !important; */
}
.crypto-addressbook .coin-item .ant-list-item-meta-content .coin-title {
  white-space: inherit;
}
/* withdraw crypto end */
.new-custom-radiobtn .ant-radio-button-wrapper-checked .icon.lg {
  background-position: -324px -521px !important;
}
.new-custom-radiobtn .ant-radio-button-wrapper .icon.lg {
  background-position: -324px -485px;
}
.new-custom-radiobtn,
.new-custom-radiobtn .ant-radio-button-wrapper,
.new-custom-radiobtn .ant-radio-button.ant-radio-button-checked,
.new-custom-radiobtn .ant-radio-button-wrapper:first-child,
.new-custom-radiobtn .ant-radio-button-wrapper:not(:first-child)::before,
.new-custom-radiobtn
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: none;
  border: 0;
}
.new-custom-radiobtn .ant-radio-button-wrapper-checked,
.new-custom-radiobtn .ant-radio-button-wrapper {
  font-weight: 500;
  font-size: 16px;
  color: rgba(250, 252, 254, 0.8);
  text-transform: capitalize;
}
.new-custom-radiobtn
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active {
  color: rgba(250, 252, 254, 0.8);
  border-color: transparent;
}
.new-custom-radiobtn
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: transparent;
}
.new-custom-radiobtn
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: transparent;
}
.new-custom-radiobtn
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: none;
}
.new-custom-radiobtn
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: rgba(250, 252, 254, 0.8);
}
/* .form-block .new-custom-radiobtn .ant-radio-button-wrapper:first-child{padding-left: 0 !important;}
.form-block .new-custom-radiobtn .ant-radio-button-wrapper:first-child{padding-left: 0!important;}
.new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){border-color: transparent;}
.new-custom-radiobtn, .new-custom-radiobtn .ant-radio-button-wrapper, .new-custom-radiobtn .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .new-custom-radiobtn .ant-radio-button-wrapper:first-child, .new-custom-radiobtn .ant-radio-button-wrapper:not(:first-child):before, .new-custom-radiobtn .ant-radio-button.ant-radio-button-checked{background: none;border: 0;}
.new-custom-radiobtn .ant-radio-button-wrapper, .new-custom-radiobtn .ant-radio-button-wrapper-checked{font-weight: 500;font-size: 16px;color: rgba(250, 252, 254, 0.8);text-transform: capitalize;}
.custom-label .ant-form-item-label, .custom-label.ant-row{display: block!important;padding: 0!important;}
.sc-error{font-weight: 400!important;}
.custom-forminput .ant-form-item-label>label{height: auto;}
.ant-form-item-label>label{margin-bottom: 4px!important;}
.ant-form-item-label>label, .label-style{color:rgba(255, 255, 255 ,0.6);font-weight: 400;font-size: 14px;margin-bottom: 4px!important;} */
/* @media Start*/
.icon.sell {
  background-position: -340px -617px;
}
.customicon:hover .icon.sell {
  background-position: -340px -552px;
}
.icon.md.status {
  background-position: -535px -85px !important;
  width: 22px;
  height: 21px;
}
.icon.md.status:hover {
  background-position: -568px -85px !important;
}
.withdraw-slider .ant-slider-mark .ant-slider-mark-text:last-child {
  right: -80px !important;
  left: unset !important;
}
.dashb-btmtable .ant-table,
.dashb-btmtable th {
  background-color: var(--bgCard) !important;
  color: #ffffff !important;
  border-bottom-color: #393e64 !important;
}
.dashb-btmtable .ant-table-placeholder:hover .ant-table-cell,
.dashb-btmtable tr:hover td {
  background-color: var(--bgCard) !important;
}
.dashb-btmtable tbody td.ant-table-column-sort {
  background: var(--bgCard);
}
.markets {
  height: 548px;
  overflow: hidden;
  margin-top: 16px;
}
.dashb-btmtable {
  height: 351px;
  overflow: auto;
}
.dashb-btmtable thead th:first-child {
  border-top-left-radius: 10px;
}
.dashb-btmtable .ant-table-cell-scrollbar {
  box-shadow: none;
}
.justify-content-end {
  justify-content: end !important;
}
.justify-content-center {
  justify-content: center !important;
}
/* .payees-form.payees-rightpanel .loader-antd{padding: 16px 0px;} */
.custom-msg {
  font-weight: 700;
  font-size: 16px;
}
.check-boxalignment .ant-checkbox-inner {
  height: 20px;
  width: 20px;
}
.min-loader {
  position: absolute;
  top: 0;
  right: 45%;
}
.buy-rpanel .loader-antd {
  padding: 0;
}
.max-loader {
  position: absolute;
  top: -16px;
  right: 34%;
}
.declaration-content .ant-modal-close-x {
  right: 18px;
  position: absolute;
  z-index: 1;
  top: 12px;
}
.justify-center {
  justify-content: center !important;
}
.alert-flex.payeees-error {
  top: -70px;
}
.bank-name {
  width: 460px;
  margin: auto;
}
.bank-name .ant-col.ant-form-item-label {
  top: -10px;
}
.summary.rightpanel-card-bg.mobile-padding.summery-panelcard
  .alert-flex:first-child {
  top: -224px;
}
.alert-flex.withdraw-alert {
  top: -72px;
}
.cust-valid-field .ant-input,
.cust-valid-field .ant-input-group-addon {
  background-color: transparent;
  border: 0;
  color: #fff;
}
.estimate-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 4px 16px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 42px;
  cursor: pointer;
  background: linear-gradient(
    89.35deg,
    #6613ba 19.31%,
    #2c00aa 51.88%
  ) !important;
}
.cust-breadcrumb span:first-child {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.cust-breadcrumb span:not(:last-child) .text-white:hover {color: #397ef6 !important;cursor: pointer;}
.cust-breadcrumb span:last-child span {
  color: var(--textWhite) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.market-flex .coin-search input:focus {
  width: 236px;
  background: var(--bgCard);
  height: 46px;
  top: 0;
}
.market-flex .coin-search input {
  top: 0px;
  width: 115px;
  position: absolute;
  right: 52px;
  z-index: 1;
  transition: 0.4s;
  text-decoration: none;
  cursor: pointer !important;
  background: var(--bgCard);
  height: 46px;
  padding: 0 12px;
}
.market-flex .coin-search {
  cursor: pointer;
  position: relative;
}
.market-flex .coin-search .coin-search-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.market-flex
  .coin-search
  .ant-input-group
  > .ant-input-affix-wrapper:not(:last-child)
  .ant-input {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.jusity-content-spacebetween {
  justify-content: space-between;
}
.coin-search .ant-input {
  font-size: 16px;
  color: #fff;
}
.coin-itemslist-scroll {
  height: 612px;
  overflow-y: auto;
}
/* notificationa start  */
.ant-drawer-wrapper-body {
  background: var(--bgCard);
}
.drawer-maintitle {
  color: #fff !important;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}
.profile-value {
  color: var(--textWhite);
  font-size: 16px;
  font-weight: 500;
}
.value-description {
  color:var(--textSecondary);
  font-size: 16px;
}
.profile-label {
  color: #fff;
}
.firstletter-design {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212121;
  border: 1px solid #e5e5e5;
  color: #fff;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  font-size: 22px;
  font-weight: 600;flex-shrink: 0;
}
.summary-width .summary-text {
  max-width: 250px;
}
.buy-selectgroup .crypto.coin {
  margin-left: 6px;
}
.ant-drawer-header {
  background: #131024;
  border-bottom: 0;
}
.side-drawer-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.ant-drawer {
  z-index: 2000;
}
.notifications-list .ant-list-item-meta-avatar {
  border-radius: 50%;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
  background-color: #4db205;
  margin-top: 7px;
}
.notifications-list .ant-list-item-meta {
  align-items: start;
}
.icon.md.withdraw,.icon.md.withdrawfiat {
  background-position: -478px -85px;
  transform: rotate(-36deg);
}
.notifications-list .ant-list-item {
  padding-left: 0;
  padding-right: 0;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0;
}
.ant-drawer-content-wrapper {
  top: 0;
}
.count-note {
  align-items: center;
  background: red;
  border-radius: 15px;
  color: #fff;
  display: flex;
  font-size: 10px;
  height: 18px;
  justify-content: center;
  position: absolute;
  right: -4px;
  top: 6px;
  width: 18px;
}
/* notification end  */
.ant-modal-footer {
  text-align: center;
  margin-top: 36px;
  margin-bottom: 16px; padding: 0;
  border-radius: 0 0 8px 8px;
}
.ant-modal-title .card-title
/* .card-disable .card-title  */
{
  text-transform: uppercase !important;
}
.card-disable .card-title {
  color: var(--textSecondary) !important;
  text-transform: none !important;
}
.icon-darkbg {
  background: rgba(241, 241, 241, 0.1);
  padding: 10px;
  border-radius: 50%;
  display: inline-block;
}
.ml-20 {
  margin-left: 20px;
}
h1.seleted-name {
  font-weight: 500;
  font-size: 26px;
  line-height: normal;
  color: #fff;
  margin-bottom: 0;
  word-break: break-all;
}
.ml-10 {
  margin-left: 10px;
}
.avail-balance {
  color: #99999a;
  font-size: 18px;
  font-weight: 500;
}
.ant-switch {
  background: linear-gradient(
    89.35deg,
    #6613ba 19.31%,
    #2c00aa 51.88%
  ) !important;
  margin: 0 8px;
}
.ant-switch-checked:focus {
  box-shadow: none;
}
.export-pdf .btn-style {
  height: 46px !important;
}
.export-pdf .btn-style:hover {
  transition: 0.5s;
  background-image: linear-gradient(
    to right,
    #2c00aa 0%,
    #6613ba 50%,
    #2c00aa 100%
  ) !important;
}
.transactions-search.d-flex {
  gap: 10px;
}
.align-center {
  align-items: center;
}
.transactions-header .cust-breadcrumb {
  margin-bottom: 0;
}
.ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
}
.ant-input-group-addon .ant-btn.validate {
  border-radius: 0px 8px 8px 0px;
  border: transparent;
  border-left: 1px solid #2c2653;
  background: #2c2653;
  align-items: center;
  height: 52px;
  min-width: 130px;
  justify-content: center;
}
.ant-input-group-addon {
  padding: 0;
}
.cust-valid-field {
  height: 54px;
}
.cust-valid-field input,
.cust-valid-field .ant-input-group {
  height: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
.ant-switch-disabled {
  cursor: not-allowed;
}
/* mega menu start  */
.profilecard-bg {
  background: var(--bgCard);
  border-radius: 20px;
  padding: 28px;
}
.wallet-item {
  border: 1px solid #397ef6;
  background: #000;
  color: var(--textWhite);
  border-radius: 20px;
  padding: 34px 30px;
}
.wallet-menu {
  border-radius: 12px;
  background: #27233a;
  box-shadow: 0px 10px 20px 0px rgba(57, 126, 246, 0.15);
  padding: 20px;
  display: flex;
  align-items: start;
  min-height: 130px;
}
.wallet-menu:hover {
  border-radius: 12px;
  background: linear-gradient(61deg, #6613ba 30.82%, #2c00aa 100%);
  box-shadow: 0px 10px 20px 0px rgba(57, 126, 246, 0.15);
}
.super-menu {
  width: 90%;
}
.wallet-item .ant-col {
  margin-bottom: 20px;
}
h1.ant-typography.supermenu-title {
  color: #f1f1f1;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.wallet-item .icon {
  height: 26px;
  width: 26px;
  margin-right: 15px;
  flex: 26px 0 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 26px;
}
.icon.bank {
  background-position: -163px -242px;
}
.icon.cards {
  background-position: -199px -243px;
}
.icon.exchange {
  background-position: -238px -243px;
}
.icon.wallet-icon {
  background-position: -280px -242px;
}
.wallet-menu:hover .icon.bank {
  background-position: -3px -242px;
}
.wallet-menu:hover .icon.cards {
  background-position: -39px -243px;
}
.wallet-menu:hover .icon.exchange {
  background-position: -78px -243px;
}
.wallet-menu:hover .icon.wallet-icon {
  background-position: -120px -242px;
}
h1.ant-typography.super-title {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-description {
  color: #e5e5e5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.activelink {
  color: #397ef6;
}
.accounttype-radio .newcustome-radiocheck .ant-radio-inner {
  background-color: #040015;
  border-color: #204483 !important;
  /* cursor: not-allowed; */
}
.btn-style:disabled:hover,
.btn-style[disabled]:hover {
  background: linear-gradient(
    89.35deg,
    #6613ba 19.31%,
    #2c00aa 51.88%
  ) !important;
  color: rgba(0, 0, 0, 0.25);
  transition: none;
}
.btn-style:disabled .icon.btn-arrow {opacity: 0.5;}
.text-wordbreak {
  word-break: break-word;
}
/* mega menu end  */
.c-pointer{cursor: pointer;}
.transaction-date{flex: 236px 0;}
.transactions .flex-width,.transactions .transaction-date{flex-grow: 1 !important;flex-shrink: 0 !important;text-overflow: ellipsis;  overflow: hidden;}
.db-table .custom-table.card{width: 1020px;}
.cust-input.phone-he.cust-phone .ant-input-group-addon .ant-select{margin: -5px -3px !important;}
.cust-input.phone-he.cust-phone .ant-select-arrow{margin-right: 2px;}
.ant-alert-message,.star{color: #ff4d4f;}
.pa-alert{text-align: left;padding: 0;margin-bottom: 12px;}
.check-error .ant-checkbox-inner,.ant-select-show-arrow .input-bordered,.ant-form-item-has-error .input-bordered{border-color: #ff4d4f !important}
.check-error{margin-bottom: 8px;}
.policy-content .ant-form-item-control-input-content{display: flex; gap: 12px;}
.gap-0 .ant-form-item-control-input-content{gap: 0;}
.register-blockwid { padding: 54px 12px; border-radius: 20px; background: #131024; margin: 0 auto;  }
.content-wrapper .content{background-color: #131024;}
.ant-btn-loading svg{color: #b8b8b8;}
.ant-select.payee-input{padding-left: 0;}
.ant-notification .ant-notification-notice{background: #052956;    border-radius: 14px;color: #fff;}
.ant-notification {top: 78px !important;z-index: 9999;}
.ant-notification svg{color: #fff;}
.ant-notification-notice-message{color: #fff;font-weight: 600;}
.toaster-close{background-position: -191px -84px;}
.download-btn{height: 43px;color: #fff;border: transparent;font-size: 18px !important;text-transform: uppercase;margin: 0;}
.mt-36{margin-top: 36px;}
.ant-spin-blur::after { opacity: 0;}
.reffername{color: #00b93f;top: -20px;  position: absolute;  right: 14px;overflow: hidden;  width: 220px;  white-space: nowrap;  text-overflow: ellipsis;  text-align: right;}
.icon.lg.greencheck{background-position: -26px -27px;top: 18px;  position: absolute;  right: 20px;}
.mb36{margin-bottom: 36px;}
.payee-input.cust-valid-field input:focus{border: transparent;box-shadow: none !important;}
.payee-input.cust-valid-field button:disabled{color: #ccc;}
.addpayee-summary .summary-list { border-bottom: 1px solid #2c2841; border-radius: 0; justify-content: space-between; padding: 16px;}
.nobank-details { padding: 16px; background: rgb(44, 38, 83); border-radius: 16px; margin: 16px 0px;}
.ant-message {
	bottom: 15px !important;
	top: auto;
}
.custom-msg .ant-message-notice-content {background: #333;color: #fff;padding: 20px 40px;min-width: 200px;}
.iban-input input{height:52px;font-size: 18px;}
.sellbg .ant-list-item-meta-avatar, .withdraw.cryptobg .ant-list-item-meta-avatar, .withdraw.fiatbg .ant-list-item-meta-avatar, .withdrawbg .ant-list-item-meta-avatar {
  background-color: #ff2400;
}
.withdraw-component{max-height: 400px;overflow: auto;}
.graph-btns{text-align: right;margin-bottom: 10px;}
.graph-btns .ant-btn{background: #000; color: #adadad; padding: 4px 12px;border-color: #204483;outline: none;border-radius: 0;}
.graph-btns .ant-btn:hover,.graph-btns .ant-btn:active,.graph-btns .ant-btn:focus,.graph-btns .ant-btn.active{border-color: #204483 !important;
  background: linear-gradient(84.34deg, #6613ba 41.55%, #2d00aa 96.49%, #2d00aa 96.49%, #2c00aa 96.49%);color: #fff;}
  .graph-btns .ant-btn:first-child{border-top-left-radius:8px ;border-bottom-left-radius: 8px;}
  .graph-btns .ant-btn:last-child{border-top-right-radius:8px ;border-bottom-right-radius: 8px;}
  .notkyc-section{height: 70vh;display: flex;align-items: center;justify-content: center;}
.w-full{width: 100%;}
.ml-0{margin-left: 0;}
.mr-0{margin-right: 0;}
.spinning-loader{width: 120px;}
.approve-email{color: #fff;}
.mt-34{margin-top: 34px;}
.withdraw-network .ant-form-item-explain-error{margin: -3px 0 0 0;}
.withdraw-network .bank-name .ant-col.ant-form-item-label { top: -13px;}
.mt-16{margin-top: 16px !important;}
.sumSub-review{font-size: 16px;}
.text-link{color: #397ef6 !important;}
.rejection-description{min-width: 214px;padding-right: 0 !important;}
.summary-text.wordwrap-break{word-break: break-word;}
.netwok-stye{gap: 16px;}
.netwok-stye .coin-item.active{background: #2c2653 !important;}
.netwok-stye .coin-item{border: 1px solid #204483 !important;background: var(--bgDark);padding-top:0;padding-bottom:0}
.deposit-list.ant-list-split .ant-list-item{cursor: default;}
/* select provider start */
.provider-block{background: transparent;border-radius: 20px;width: 100%;margin: 0px auto;height: auto;padding: 0;max-width: 465px;}
.provider-title{ text-align: center; font-size: 1rem; font-weight: 600; color: rgb(229, 229, 229);}
.radio-section img{width: 44px;}
.radio-section .ant-space-item{padding: 12px;width: 100%;}
.radio-section,.radio-section .ant-space.ant-space-vertical{width: 100%;}
.radio-section{margin-top: 30px;}
.radio-section .ant-radio-wrapper{padding: 16px;margin-bottom: 16px;font-size: 18px;font-weight: 500;color: #fff; display: flex;
  flex-direction: row-reverse; justify-content: space-between;margin-right: 0;background: var(--bgDark);
  border: 1px solid #204483 !important; border-radius: 12px; box-shadow: 0 8px 10px rgba(57, 126, 246, .15);}
  .radio-section .ant-radio-wrapper.ant-radio-wrapper-checked{background: #2c2653;}
  .radio-section span.ant-radio + span{flex: 1;margin-top: 0;}
.provider-block .ant-radio{top: 0;}
.content-center{min-height: 70vh;display: flex;align-items: center;justify-content: center;}
.gap-8{gap: 8px;}
.mt-32{margin-top: 32px;}
/* select provider end  */
/* login btn start  */
.login-btn.ant-btn{background: transparent;color: #fff;border: 1px solid #6613ba;border-radius: 20px;}
.login-btn.ant-btn:hover,.login-btn.ant-btn:active,.login-btn.ant-btn:focus{background-image: linear-gradient(84.34deg, #6613ba 41.55%, #2d00aa 96.49%, #2d00aa 96.49%, #2c00aa 96.49%);}
/* login btn end  */
/* deposit address start  */
.deposit-address-input input{background-color: transparent; border: 1px solid #6269a3 !important;border-radius: 8px; font-size: 18px; text-align: left; padding-left: 18px !important;color: #fff !important;line-height: normal;height: 54px;}
.deposit-address-input .ant-input-affix-wrapper{padding: 0;background: transparent;outline: none;border: none;}
.deposit-address-input .ant-input-affix-wrapper:focus,.deposit-address-input .ant-input-affix-wrapper:hover,.deposit-address-input .ant-input-affix-wrapper-focused{z-index: 0 !important;box-shadow: none;}
.mt-60{margin-top: 60px;}
.coin-style{width: 30px;border-radius: 50%;color: #fff;}
.network-image{margin-right: 6px;display: inline-block;border-radius: 50%; margin-left: 4px;}
.w-fit-auto{width: fit-content;margin: 4px auto;}
/* deposit address end  */
/* wallet mail login start  */
.xar-container{background-color: #1d1838 !important;}
#xar-modal{ backdrop-filter: blur(3px);}
.xar-header-heading{color: #fff;}
.xar-email-login__input{ background-color: transparent !important; border: 1px solid #6269a3 !important; border-radius: 8px; font-size: 18px; line-height: 50px;  text-align: left;}
.xar-btn{ min-height: 3rem !important; border-radius: 0.5rem !important; padding: 0px 2rem !important; width: 100% !important; border: 0px !important; color: rgb(255, 255, 255) !important; background-color: rgb(20, 97, 219) !important;}
.xar-header-subtext{color: #fff;}
.markets-input-search .align-center.market-flex{align-items: start;}
.markets-input-search .coin-search.dt-topspace{margin-top: 0 !important;}
.xar-otp-input{border: 1px solid #6269a3 !important;}
.leftcard.crypto-wallet .ant-empty.ant-empty-normal{ margin: 2px auto;}
.ant-btn.disconnect-btn{border: 1px solid var(--bgSelect); border-radius: 42px; padding: 10px; width: 100%; background: transparent; height: auto; line-height: normal;color: #fff;}
.ant-btn.disconnect-btn:hover, .disconnect-btn:hover{ background: linear-gradient(89.35deg, #6613ba 31.31%, #2c00aa 94.88%);}
.btn-transparent{background: transparent;border: 0;padding: 0; color: var(--textPrimary) !important;}
/* wallet mail login end  */
.xar-header-logo__container{display: block !important;border: 0 !important;}
/* wallets banner start  */
.wallets-title{font-size: 40px;font-weight: 800;color: #fff;}
.wallets-title span{font-size: 40px;line-height: normal;display: inline-block;font-weight: 500;}
.align-items-center{align-items: center;}
.justify-end{justify-content: end;}
.mt-24{margin-top: 24px;}
.text-linkblue{color: #397ef6;}
.option-flex li{font-size: 18px;}
.mb-12{margin-bottom: 12px;}
.line-hnormal{line-height: normal;}
/* wallets banner end  */
.deposite-network .ant-form-item-label label{height: auto;}    
.deposite-network .ant-col.ant-form-item-label{top: -8px !important;padding-left: 6px;}    
.network-style{border: 1px solid #204483;padding: 4px 16px;font-size: 16px;color: var(--placeholderText);border-radius: 8px;background: transparent;cursor: pointer;}
.network-style.active{background: linear-gradient(89deg, rgba(102, 19, 186, 0.60)31.31%, rgba(44, 0, 170, 0.60)94.88%);color: #fff;}
.network-section{display: flex;align-items: center;flex-wrap: wrap;justify-content: center;gap: 8px;margin-bottom: 24px;}
.current-network{color: #397ef6;font-size: 14px;font-weight: 500;}
.change-network .ant-select-selector{height: auto !important;width: 118px !important;}
.switch-network{display: flex;align-items: center;gap: 10px;justify-content: center;margin: 6px 0 8px 0;}
.switch-network .ant-select-selector .ant-select-selection-placeholder{line-height: normal !important;}
.switch-network .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: auto !important; }
.switch-network .ant-select-arrow{margin-right: 0;}
.switch-network .ant-select-selection-item{color: #fff;}
.switch-btn{background-image: linear-gradient(to right, #2c00aa 0%, #6613ba 50%, #2c00aa 100%) !important;  color: var(--textWhite);font-size: 14px;border: none;border-radius: 24px;}
.network-loader .ant-spin-spinning{transform: scale(0.6);height: 40px;}
.network-loader .ant-spin-dot{top: -17px;}
.recipient-details .summary-list-item:last-of-type{border-bottom:0;}
.mt-30{margin-top: 30px;}
.icon.excel { background-position: -542px -31px !important; height: 24px; width: 24px;}
.transactions-actions .btn-style{  margin-left: 0px; padding: 0 11px;margin-top: 0;margin-bottom: 0;}
.ant-picker-suffix, .ant-picker-range-separator .ant-picker-separator {color: #fff;}
.transactions-filters .input-bordered{font-size: 16px;}
.transactions-filters .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 53px !important;}
.transactions-filters .ant-select-single .ant-select-selector .ant-select-selection-placeholder {line-height: 54px !important;}
.transactions-filters .ant-select-single .ant-select-selector .ant-select-selection-item,.transactions-filters .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height: inherit;}
.h-54{height: 54px;}
.flex-one{flex: 1;}
.transactions-filters{gap: 50px;align-items: center;margin-bottom: 10px;}
.icon.refresh-white { background-position: -596px -82px !important; height: 24px; width: 24px;}
.icon.reset {background-position: -623px -81px !important;  height: 24px; width: 24px;}
.ant-picker-input input { color: #fff; font-size: 16px;}
.ant-btn.login-filled-btn,.ant-btn.login-filled-btn{background: linear-gradient( 89.35deg, #6613ba 19.31%, #2c00aa 51.88%) !important;font-size: 16px;font-weight: 600;color: #fff;height: 46px;width: fit-content;border-radius: 42px; padding: 0 26px;text-transform: uppercase;display: flex; align-items: center; gap: 30px;border: transparent;margin: 0 auto;}
.banner-shadow{background-image: url("../images/banner_shadow.svg");background-position: center;padding-bottom: 50px;padding-top: 50px;}
.crypto-img{width: 710px;}
.banner-shadow::before { content: ''; position: absolute; top: 50px; left: 0;width: 100%; height: 100%;
  background-image: url('../images/galaxy_bg.svg');  background-size: 140%; background-position: center; background-repeat: no-repeat; z-index: -1; 
  /* animation: galaxyAnimation 20s linear infinite; */
}
.gap16{gap: 16px;}
@keyframes galaxyAnimation {
  0% {
      transform: rotate(0deg) scale(1);
      opacity: 1;
  }
  50% {
      transform: rotate(180deg) scale(1.1);
      opacity: 0.8;
  }
  100% {
      transform: rotate(360deg) scale(1);
      opacity: 1;
  }
}
.text-capitalize{text-transform: capitalize;}
.note-tspace{margin-top: 0 !important;}
.text-breakall .ant-alert-description{word-break: break-all;}
.customlogo { width: auto;height: 36px;}
.add-cryptoadrs .input-bordered:not(.ant-select){padding-right:18px}
.icon.lg.close { background-position: -342px -180px;}
.ml-4{margin-left: 4px;}
.ant-picker-suffix{pointer-events: all;cursor: pointer;}
.view-more { font-size: 16px; vertical-align: middle;}
.justify-content-between{justify-content: space-between;}
.flex-1{flex: 1;}
.seleted-name{color: #fff !important;}
.nonexchange-orderh .db-table .custom-table.card {   width: 895px;}
.gap-16{gap: 16px;}
.orderhistory-table tr{background: #131024;}
.orderhistory-table td,.alltransactions-table td{background: #131024; padding: 10px;align-content: start;}
.orderhistory-table,.alltransactions-table{border-collapse: separate;border-spacing:0 10px;}
.orderhistory-table td:first-of-type,.alltransactions-table td:first-of-type{border-top-left-radius: 10px;border-bottom-left-radius: 10px;}
.orderhistory-table td:last-of-type,.alltransactions-table td:last-of-type{border-top-right-radius: 10px;border-bottom-right-radius: 10px;}
.dashboard-transactions .orderhistory-table{min-width: 1150px !important;max-height: 379px;overflow-y: auto;}
.dashboard-transactions .infinite-scroll-component{height: 400px !important;}
@media (min-width: 768px) {
  .recipient-details .summary-list-item .summary-text{width: 70%;}
  .option-flex{display: flex;gap: 40px;padding-left: 16px;margin-bottom: 50px;}
  .buysell-iframe{width: 465px;margin: 0px auto 0;display: inline-block;border-radius: 20px;}
  .comingsoon-section{height: 80vh;background-image: url("https://devdottstoragespace.blob.core.windows.net/neoimages/neo-coming-soon.png");background-position: center;background-size: contain;background-repeat: no-repeat;
    display: flex;align-items: center;justify-content: center;}
  .qr-image{width: 200px !important;height: 200px !important;}
  .addpayee-summary .summary-list{display: flex;align-items: center;justify-content: space-between;}
  .payee-summary-bank .summary-list-item .summary-text{overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
  .withdraw-section .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot { left: 45%;}
  .tx-space{padding: 0 24px;}
  .trans-card{width: 700px;margin: auto;}
  .summary-list-item.summaryList-total{width: 465px; margin: auto; margin-bottom: 10px}
  .register-blockwid { padding: 54px 64px;  width: 600px; }
  .coin-selctloader .ant-spin-dot{left: 45% !important;}
  .sell-minvalue .ant-slider-mark .ant-slider-mark-text-active.ant-slider-mark-text:first-child strong{margin-left: 50px !important;}
  .super-menu {
    left: 69.406px !important;
  }
  .payee-inblock .summary-list-item:last-child {
    margin-bottom: 16px;
  }
  .card-paddingrm {
    margin-top: 16px;
  }
  .d-lg-flex {
    display: flex;
  }
  .transactions-header .coin-search-input {
    width: 350px;
  }
  .db-faitwallet,
  .crypto-wallet {    
    overflow-y: auto;
    padding-top: 0;
    height: 317px;
    scrollbar-width: none;
  }
  .db-faitwallet .custom-flex,
  .crypto-wallet .custom-flex {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 16px;
    background-color: #000;
  }
  .ant-drawer-content-wrapper {
    width: 520px !important;
  }
  .buy-token .buy-from {
    text-transform: uppercase;
    font-weight: 600;
  }
  .buy-token {
    width: 465px;
  }
  .dashboard-transactions {
    height: 473px;
  }
  .dashboard-transactions.markets-input-search{ height: 532px;}
  .mobile-dt-header {
    margin-left: 50px;
  }
  .db-portfolio {
    min-height: 530px;
  }
  .max-content {
    width: 460px;
    margin: auto;
  }
  .howit-works {
    min-height: 353px;
  }
  .custom-steps {
    margin: -58px 0 20px 0;padding: 0;
    background-color: transparent;
  }
  .summary-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
  }
  .history-data:not(:last-child) {
    margin-bottom: 0;
  }
  .orderhistory .order-item {
    display: flex;
  }
  /* .payees-form{width: 560px; margin: 0px auto;} */

  .k-pager-info.k-label {
    display: block;
  }
  .icon-circle {
    width: 65px;
    height: 65px;
  }
  .icon.xxl.cart {
    background-position: -8px -616px;
    transform: none;
  }
  .icon.xxl.uparrow {
    background-position: -95px -617px;
    transform: none;
  }
  .icon.xxl.downarrow {
    background-position: -266px -618px;
    transform: none;
  }
  .icon.xxl.wallet {
    background-position: -181px -617px;
    transform: none;
  }
  .customicon:hover .icon.xxl.cart {
    background-position: -8px -550px;
    transform: none;
  }
  .customicon:hover .icon.xxl.downarrow {
    background-position: -266px -551px;
    transform: none;
  }
  .customicon:hover .icon.xxl.uparrow {
    background-position: -95px -550px;
    transform: none;
  }
  .customicon:hover .icon.xxl.wallet {
    background-position: -181px -552px;
    transform: none;
  }
  .cust-xxl.crypto.coin.sm.gbp {
    background-position: -501.3px -533px;
    transform: scale(1.8);
  }
  .cust-xxl.crypto.coin.sm.chf {
    background-position: -174.3px -571px;
    transform: scale(1.8);
  }
  .rightpanel-card-bg {
    padding: 36px;
  }
  .btn-style.ant-btn {
    font-size: 30px !important;
    width: 465px;
  }
  .comingsoon-section .btn-style{max-width: 233px;font-size: 16px !important;margin-top: 150px;height: 46px !important;padding: 0 24px;}
  .pannel-bg,
  .cust-breadcrumb {
    margin-left: 0;
    margin-right: 0;
  }
  .custom-modal .ant-modal-content {
    padding: 36px 36px;
  }
  .layout-bg.right-panel {
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
  }
  .note-text {
    padding: 36px 0px;
  }
  .summary.rightpanel-card-bg .sm-card-title {
    text-align: center;
    padding-left: 0px;
  }
  .deposit-crypto .btn-style.ant-btn {
    width: 100%;
  }
  /* .custom-modal .ant-modal-content{padding: 36px 0px;position: absolute;width: 774px;right: -258px;transform: translateY(12%);} */
  .custom-modal .ant-modal-header {
    padding: 0px 24px;
  }
  .custom-modal .coin-item:hover {
    background: #2c2653;
  }
  .coin-item.active {
    background: #2c2653;
  }
  .custom-modal .ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: left;
    vertical-align: middle;
  }
  .ant-modal-content {
    height: auto !important;left: -260px !important;
  }
  .select-Token {
    margin-top: 40px;
  }
  .buy-from {
    font-size: 16px;
  }
  .custom-table .ant-table {
    background-color: transparent;
    overflow: auto;
  }
  .custom-table .ant-table-tbody > tr > td {
    color: var(--textPrimary);
  }
  .mb-width {
    width: auto;
  }
  .mb-width-high {
    width: auto;
  }
  .agree-input {
    width: 100%;
  }
  .bank-details {
    width: 100%;
  }
  .dt-input-title {
    margin: 36px 0 16px !important;
  }
  .verify-btn {
    font-size: 18px;
  }
  .dashb-btn .btn-style {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 0;
  }
  .payee-label {
    font-size: 14px !important;
    line-height: 20px;
  }
  .agree-input .ant-select-selector {
    width: 476px !important;
  }
  /* .custom-modal{width: 730px !important;} */
  .flex-width {
    flex: 120px 0 0 !important;
  }
  .flex-width.value-feild {
    flex: 200px 0 0 !important;
  }
  .flex-width:first-child {
    flex: 180px 0 0 !important;
  }
  .custom-table .table-date {
    margin-left: 20px;
  }
  .layout-bg.left-panel.mb-show {
    display: none;
  }
  .grid-search {
    width: 300px;
  }
  .market-search.grid-search {
    width: 180px;
  }
  .market-search.grid-search .search-box:hover > .search-text {
    width: 165px;
  }
  /* .dt-input-title{text-align: center;} */
  .deposit-main .note-text {
    padding: 10px 0px !important;
  }
  .left-items-stretch {width: auto;margin: 0 15px 15px;}
  .coinbal-tabs button:nth-child(1) {margin-right: 5px !important;}
  .error-border-iban.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus{border: 0;box-shadow: none;}
  .cust-cards {
    display: flex;
    width: 465px !important;
    margin: 0px auto;
    margin-bottom: 15px;
  }
  /* .summary-list-item{width: 460px;margin: 0 auto 6px;} */
  .check-boxalignment {
    margin: 0px auto;
    width: 465px;
  }
  .search-content {
    display: flex;
  }
  .dashboard-port {
    margin: 0px !important;
    margin-bottom: 16px !important;
  }
  .wd-search.wd-p {
    width: 100%;margin: auto;
  }
  .wd-search.wd-p .selectbank-list.dt-topspace {
    width: 465px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .d-lg-flex{display: flex;}
  .frame-height{min-height: 550px;}
  /* popup start  */
  .db-portfolio {
    min-height: 439px !important;
  }
  .custom-modal .ant-modal-body {
    width: 458px;
    margin: 26px auto;
  }
  .physicalcards-ordergrid .order-hstry h4 {
    width: 100px !important;
  }
  .md-none {
    display: none !important;
  }
  /* .custom-modal {
    width: 730px !important;
  } */
  .ant-modal-wrap.ant-modal-centered {
    top: 177px;
  }
  .ant-modal-header {
    padding-left: 0 !important;
  }
  .ant-modal-footer {
    max-width: 465px;
    margin: 0 auto;
  }
  /* popup end  */
  .market-flex .coin-search input:focus {
    width: 192px;
  }
  .market-flex .coin-search input {
    width: 100px;
  }
  .markets-input-search .coin-search input{width: 192px;}
  .mb-coin-lookup {
    display: none;
  }
  .header-right {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: right;
  }
  .dt-bank {
    margin: 30px 120px;
  }
  .dt-benefiary {
    background-color: var(--bgCard);
    border-radius: 20px;
    padding: 35px 100px;
  }
  .dt-input-title {
    font-size: 22px !important;
  }
  .input-label {
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: var(--textSecondary);
  }
  .fiat-summary .input-label{font-size: 14px !important;}
  .input-value {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: var(--textPrimary);
    text-align: right;
    margin-bottom: 0 !important;
  }

  .deposit-select {
    margin: 0px auto;
    max-width: 465px;
  }
  /* .custom-modal {
    width: 648px !important;
  } */
  .visible-mobile,
  .sideropen {
    display: none;
  }
  .wd-entry {
    width: 460px;
  }
  .row-stretch {
    align-items: stretch;
    justify-content: space-between;
  }
  .left-items-stretch {
    width: 375px;
  }
  .layout-bg.right-panel.pannel-bg {
    flex: 1;
    width: 400px;
    margin: 0 15px 15px 0px !important;
  }
  .layout-bg.right-panel {
    flex: 1;
  }
  .ant-row {
    display: flex !important;
  }
  .check-boxalignment {
    width: 464px;
    margin: 0 auto 6px;
  }
  /* .summary-list-item{width: 460px;margin: 0 auto 6px;} */
  .cust-cards {
    display: flex;
  }
  .cust-summary-width {
    width: auto;
    margin: 0 auto;
  }
  .earn-card {
    width: 224px;
  }
  .left-panel.pannel-bg.mb-leftpanel.leftcard .ant-list-items {
    height: 240px;
    overflow: auto;
  }
  .sell-rtpanel
    .card-paddingrm
    .card-paddingadd
    .cust-summary-width
    .cust-cards
    .flip-icon
    .icon.xlg.flip {
    transform: none;
  }

  .custom-modal .ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: left;
    vertical-align: middle;
  }
  .ant-modal-centered {
    text-align: right;
    width: 1220px;
    margin: auto;
  }
  .ant-modal-mask {
    display: none;
  }
  .ant-modal-wrap {
    position: absolute;
    bottom: auto;
    top: 178px;
    left: -58px;left: -182px;
  }
  .withdraw-summerypanel {
    flex: 1;
    width: 700px;
    text-align: center;
    padding: 16px !important;
  }
  .summary-list.crypto-agree {
    width: 480px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .cuat-auth-width {
    width: 900px !important;
  }
  .sumSub-confirm {
    width: 800px;
  }
  .list-item.visible-mobile {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .left-panel .ant-list.ant-list-split{max-height: 1100px !important;}
  .ant-modal-wrap {
    left: -251px;
    top: 115px;
  }
  .withdraw-rightpanel {
    margin: 0px !important;
    width: 500px;
  }
  /* .dashboard-port, .leftcard{height: 395px;} */
  /* popup start  */
  .custom-modal .ant-modal-content {
    height: calc(100vh - 100px) !important;
    width: 580px;
    transform: none;
  }

  /* popup end  */
}

@media (min-width: 1200px) {
  /* popup start  */
  .ant-modal-content {
    left: -21px !important;
  }
  /* popup end  */
  .custom-modal.cust-popup .ant-modal-content {
    top: 0 !important;
  }
  .main-container {
    width: 1150px;
    margin: auto;
  }
  .content-area {
    width: 465px;
    margin: 0px auto;
  }
  .custom-modal .ant-modal-content {
    height: calc(100vh - 45px);
    width: 723px;
  }
  /* .custom-modal{width: 646px;} */
  .ant-modal-wrap {
    left: 48px;
  }
  /* .ant-modal.custom-modal{height: calc(100vh - 160px) !important;} */
  .ant-modal-wrap > .dep-modal {
    left: 0px !important;
  }
  .custom-modal {
    width: calc(100% - 426px) !important;
    margin: 0 12px 0 0;
  }
  /* .custom-modal .ant-modal-content{height: calc(100vh - 41%);} */
  /* .custom-modal.dep-modal .ant-modal-content{height: calc(100vh - 58%);} */
  .custom-modal.dep-modal {
    width: 768px !important;
  }
  /* .custom-modal .ant-modal-close-x{margin: 36px;} */
  .layout-bg.right-panel.pannel-bg {
    margin: 0 15px 15px;
  }
  .custom-modal.dep-modal .ant-modal-content {
    height: calc(100vh - 100px);
  }
  .custom-modal
    .ant-modal-content
    .ant-modal-body
    .coin-container
    .ant-list-items {
    height: 132px;
  }
  .custom-modal.dep-modal
    .ant-modal-content
    .ant-modal-body
    .dt-topspace
    .ant-list-items {
    height: 185px;
  }
  .ant-modal-content {
    left: 0px !important;
    top: 0px;
  }
  .withdraw-rightpanel {
    margin: 0 15px 15px 0px !important;
    width: 400px;
  }
  /* .rightpanel-card-bg{padding: 65px 110px;} */
  /* .payees-rightpanel{width: 650px;}
	.payees-form{width: 650px;} */
  .withdraw-rcard {
    margin: 10px 0px 20px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .custom-modal .ant-modal-content {
    width: 740px;
  }
  .ant-modal-wrap {
    left: -100px;
  }
  .custom-modal {
    width: calc(100% - 453px) !important;  margin: 0 12px 0 0;
  }
  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1400px) {
  .recipient-details{ margin: auto; width: 700px;}
  .frame-height{min-height: 560px;}
  .market-flex .coin-search input:focus {
    width: 234px;
  }
  .markets-input-search .coin-search input{width: 234px;}
  .howit-works {
    min-height: 338px;
  }
  .db-portfolio {
    min-height: 440px;
  }
  .main-container {
    width: 1325px;
    margin: auto;
  }
  .custom-modal .ant-modal-header {
    padding: 0px 24px;
  }
  /* .custom-modal .ant-list.ant-list-split{height: 278px;overflow: auto;} */
  .custom-modal .coin-item:hover,.custom-modal .coin-item.active{
    background: #2c2653;
  }
  .coin-item.active {
    background: #2c2653;
  }

  .custom-modal .ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: left;
    vertical-align: middle;
  }
  .custom-modal {
    margin: 0 20px 0 0; width: calc(100% - 426px) !important;
  }
  .ant-modal-wrap > .dep-modal {
    width: 800px;
    left: 12px;
  }
  .custom-modal .ant-modal-centered {
    text-align: right;
    width: 1150px;
    margin: auto;
  }
  .wd-entry {
    width: 460px;
  }
  .custom-modal .ant-modal-close-x {
    margin: 36px;margin-top: 0;
  }
  .card-title {
    font-size: 22px !important;
    font-weight: 500 !important;
  }
  .db-faitcard-bal,
  .db-fat-dollar {
    font-size: 26px;
  }
  /* .custom-modal .ant-modal-content{height: calc(100vh - 12%) !important;} */
  .right-bgpannel {
    height: 288px;
  }
  .db-portfolio,
  .leftcard {
    height: auto;
  }
  .leftcard.crypto-wallet{height: 319px;}
  /* .ant-modal.custom-modal.dep-modal{height: calc(100vh - 135px) !important;} */
  .custom-modal
    .ant-modal-content
    .ant-modal-body
    .coin-container
    .ant-list-items {
    height: 222px;
  }
  .custom-table.card {
    width: 1290px;
  }
  /* .db-faitwallet .coinval-width {
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
  .table-grid .custom-table.card {
    margin-bottom: 8px !important;
  }
  .table-grid:last-child {
    margin-bottom: 0px !important;
  }
  .register-blockwid {
    width: 600px !important;
    margin: 0 auto !important;
  }
  .cuat-auth-width {
    width: 900px !important;
  }

  /* .left-items-stretch{width: 350px;} */
  /* popup start  */
  .custom-modal .ant-modal-content {
    height: 40vw !important;
    left: -5px !important;
    width: 902px;
  }
  .ant-modal-centered {
    margin: auto;
    text-align: right;
    width: 1325px;
  }
  .ant-modal-wrap {
    bottom: auto;
    left: 3px;
    position: absolute;
    right: 0;
    top: 172px;
  }
  /* popup end  */
}
@media (max-width: 1200px) {
  .ant-modal-content {
    left: 61px !important;
  }
  .ant-modal-wrap.ant-modal-centered {
    top: 177px;
  }
  /* .withdraw-summerypanel{width: 400px;} */
  .mobile-padding {
    padding: 36px 20px !important;
  }
  .withdraw-rightpanel {
    margin-right: 15px !important;
    width: 500px;
  }
}
/* @media (max-width: 1180px){
    .ant-modal-content{ height: calc(120vh - 120px) !important;width: calc(95% - 15px);left: 61px !important;}
	.ant-modal-wrap.ant-modal-centered{ top: 177px;}

} */
@media (max-width: 1024px) {
  .payees-rightpanel {
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .crypto-img { width: 100%;}
  .frame-height{min-height: 560px;}
  .transactions{width: 1380px;}
  /* popup start  */
  .card-list {
    background: var(--bgDark);
    border: 1px solid #204483 !important;
    border-radius: 12px;
    box-shadow: 0 8px 10px rgba(57, 126, 246, 0.15);
  }
  .coin-search-input,
  .coin-search-input .ant-input-group-addon {
    background: #040015;
    border: 1px solid #204483 !important;
  }
  .buy-token.md-none .buy-coinselect {
    justify-content: space-between;
  }
  .buy-token {
    width: 100% !important;
  }
  .mobile-drop .ant-modal-footer,
  .mobile-drop .ant-modal-close-x {
    display: none;
  }
  .sm-none {
    display: none;
  }
  .ant-modal-wrap.ant-modal-centered,
  .ant-modal {
    top: 60px;
    bottom: unset;
  }
  .ant-modal-mask {
    backdrop-filter: blur(7px);
  }
  .ant-modal-centered {
    text-align: left;
  }
  .ant-modal-content {
    height: 100vh !important;
    left: 0 !important;
  }
  .custom-modal {
    width: 100% !important;
  }
  /* popup end  */
  .market-flex .coin-search .coin-search-input {
    background: var(--bgCard);
    border: 0 !important;
  }
  .market-flex .coin-search input:focus {
    width: 276px;
  }
  .wt-verify {
    margin: 0 auto;
    text-align: center;
    padding-left: 0;
    width: 215px;
  }
  .agree-container {
    padding: 0px 15px;
  }
  .main-container {
    margin: 0 10px;
  }
  .db-quicklinks {
    padding: 16px;
  }
  .withdraw-rightpanel {
    margin-right: 10px !important;
    width: auto !important;
  }
  .mobile-dt-header {
    display: none;
  }

  .sideropen .ant-menu-vertical,
  .ant-menu-vertical {
    border-right: 0 !important;
  }
  .mobile-dt-header,
  .head-textfield {
    display: none;
  }

  .mobile-headerview li {
    padding: 0 8px !important;
  }
  .mobile-header .menu-item {
    display: block;
    padding-left: 0;
  }
  .mobile-header .menu-item li {
    padding: 8px 12px;
    cursor: pointer;
  }
  .mobile-header .menu-item .list-item {
    margin: 0;
  }
  .mobile-header .main-menu .list-item.active::after,
  .mobile-header .main-menu .list-item:hover::after {
    content: none !important;
  }
  .custom-header {
    position: relative;
    width: 100%;
  }
  .sideropen {
    margin-left: -16px;
    padding: 12px;
    margin-top: 5px;
    border-radius: 0;
    min-height: 100vh;
    position: absolute;
    right: 0;
    z-index: 1;
    top: 54px;
    background: var(--bgCard);
  }
  /* .ant-layout-sider-collapsed{margin-top: 5px;border-radius: 0;min-height: 100vh; position: absolute;
		right: 0;
		z-index: 1;
		top: 45px;
		background: #171bee;} */
  .custom-header .ant-layout-sider {
    min-height: auto;
    margin-left: -16px;
    margin-top: 5px;
  }
  .sideropen .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
  .sideropen .list-item.ant-menu-item-selected .ant-menu-title-content {
    min-width: 120px !important;
    padding: 8px 16px !important;
  }
  .ant-layout.ant-layout-has-sider {
    flex-direction: inherit !important;
  }
}
/* @media (max-width: 768px){
	.ant-modal-content{left: -336px !important;
		width: calc(96% - 0px);
		height: calc(92vh - 120px) !important;}
		.ant-modal-wrap{top:1005px !important;}
		

} */
@media (max-width: 767.98px) {
  .customlogo { width: auto; height: 25px;}
  .xs-flex{display: flex !important;}
  .sm-flex-end{justify-content: flex-end;}
  .proceed-btn{width: 100%;}
  .card-paddingadd.wigetp-0{padding: 10px 12px !important;margin-top: 10px;}
  .non-cust-actions .text-right.addon-icon{padding: 14px;}
  .recipient-details{padding: 0 16px;}
  .buysell-iframe{width: 100%;margin: 40px auto 0;display: inline-block;border-radius: 20px;}
  .coinBal-flex.summary-page { padding: 0px 12px;}
  .custauth-mb.auth-phverification .custom-label label{font-size: 8px !important;}
  .custauth-mb.auth-phverification.ant-col-xs-24{flex: 1 !important;}
  .otp-verify .ant-form-item-extra span{float:right !important;}
  .mobile-btnalign.d-flex{display: block !important;}
  .mobile-btnalign .cust-right-border{border-bottom-right-radius: 8px !important;border-bottom-left-radius: 8px !important;border-top-left-radius: 0 !important;border-top-right-radius: 0px !important;border-left:1px solid #6269a3 !important;}
  .mobile-btnalign input{border-bottom-right-radius: 0px !important;border-bottom-left-radius: 0px !important;border-top-left-radius: 8px !important;border-top-right-radius: 8px !important;text-align: center !important;}
  .mobile-btnalign input::placeholder{text-align: center;margin-left: 30px;}
  .comingsoon-section .btn-style{margin-top: 110px;}
  .comingsoon-section{background-image: url("https://devdottstoragespace.blob.core.windows.net/neoimages/neo-coming-soon.svg");background-position: top;background-size: 100%;background-repeat: no-repeat;
    display: flex;align-items: center;justify-content: center;height: 80vh;}
  .buy-from, .buy-fromcurrency{line-height: normal;}
  .deposit-crypto .buy-fromcurrency{flex-shrink: 0;margin-left: 0px;}
  .deposit-crypto .buy-from{font-size: 12px;}
  .coin-container.payee-inblock .coin-title{white-space: inherit;}
  .payee-inblock .btn-style{justify-content: space-between;}
  .btn-overflowdot{width: fit-content !important;}
  .label-card { left: 5px;font-size: 10px; top: -15px;}
  .panel-balhead,.panel-balcoin{font-size: 20px;}
  .buy-max span {font-weight: 700; font-size: 10px;}
  .thank-btn{width: 100% !important;}
  .payee-inblock .summary-list-item{height: auto;}
  .sm-mb-20{margin-bottom: 16px;} 
  .sm-p-0{padding: 0 !important;}
  .flex-width{flex: 0 0 170px;}
  .sm-mt{margin-top: 42px;}
  .buy-entryinput{font-size: 24px;}
  .db-portfolio {
    height: auto;
  }
  .ant-slider-mark-text {
    font-size: 12px;
  }
  .export-pdf .btn-style {
    margin: 12px 0 0 auto !important;
  }
  .transactions-header,
  .transactions-search {
    display: block !important;
    margin-top: 12px;
  }
  .coinBal-flex.mb-0 {
    flex: 1;
  }
  .list-item.active a {
    color: #397ef6 !important;
  }
  .dt-selectext,
  .note-text {
    padding: 10px 16px;
  }
  hr,
  .earn-card {
    width: auto;
  }
  .icon.sm.alert-close {
    flex-shrink: 0;
  }
  .ant-modal-content {
    height: 100vh !important;
  }
  .db-mobile .ant-col.mb-order-1 .ant-col:last-child {
    flex: 1;
  }
  .sm-m-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .db-mobile .ant-col.mb-order-3,
  .db-mobile .ant-col.mb-order-2,
  .db-mobile .ant-col.mb-order-1 .ant-col:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .payee-inblock,
  .summary-panel {
    padding: 24px 12px;
    width: auto;
    background-color: transparent;
  }
  /* .coinBal-flex{flex: 1;} */
  .bank-name {
    width: auto;
  }
  .wd-search.wd-p .selectbank-list.dt-topspace {
    width: auto;
  }
  .mobile-space .coinbal-tabs {
    margin-top: 14px;
  }
  .copy-code {
    font-size: 14px;
  }
  .panel-card.buy-card.text-center.cust-drpdwn .summary-list-item {
    text-align: left;
  }
  .db-quicklinks .icon.sell,
  .db-quicklinks .icon.xxl.uparrow,
  .db-quicklinks .icon.xxl.downarrow {
    transform: scale(0.5) !important;
  }
  .db-quicklinks .icon.xxl.uparrow,
  .db-quicklinks .icon.xxl.downarrow{rotate: 138deg;}
  .head-menutogle img {
    width: 100px;
  }
  .panel-card.buy-card {
    padding: 0;
  }
  .buy-entryinput {
    text-overflow: ellipsis;
  }
  .summary-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .loader-antd {
    padding: 0 !important;
  }
  .card-paddingrm .ant-form-item-explain-error {
    width: 460px;
    margin: -40px 11px;
  }
  .withdraw-network .ant-form-item-explain-error{margin: 0px 0px;}
  .withdraw-network .bank-name .ant-col.ant-form-item-label{padding-bottom: 0;}
  .coinBal-flex.flex-mb {
    display: block;
    justify-content: normal;
    margin-bottom: 8px;
  }
  .dt-bank-ml .buy-from {
    font-size: 14px !important;
  }
  .buy-slider.ant-slider {
    width: 232px;
    text-align: center;
    margin: 0 auto 40px;
  }
  .coinbal-tabs {
    width: 100%;
  }
  .coinbal-tabs .tabs-button {
    width: 50%;
  }
  .layout-bg.left-panel.mb-hide {
    display: none;
  }
  .buy-token.mb-100 {
    width: 100%;
    margin-bottom: 0;
  }
  .dt-input-title {
    margin: 29px 0 10px !important;
  }
  .summary-label,
  .summary-text {
    text-align: left;
    width: auto;
    display: block;
  }

  .wt-verify {
    margin: 0 auto;
    text-align: center;
    padding-left: 0;
    width: 215px;
  }
  .buy-entryinput.wd-entry {
    font-size: 28px;
  }
  .summary.rightpanel-card-bg .sm-card-title {
    padding-left: 0;
  }
  .agree-container {
    padding: 0px 10px;
  }
  .wd-search .coin-item.wd-addressbook,
  .wd-search .coin-search-input {
    width: auto !important;
  }
  .wd-search {
    width: auto;
  }
  .wd-p {
    padding: 0px 5px;
  }
  .db-quicklinks {
    margin: 0px 8px 10px;
  }
  .mb-leftpanel {
    margin-bottom: 0px !important;
  }
  .flex-mb.wd-flex {
    display: flex;
    justify-content: space-between;
  }
  .thankyou-main {
    padding: 20px !important; background-size: 100%;
  }
  .summary {
    padding: 30px 20px;
  }
  .currency-panel {
    margin-left: 10px !important;
  }
  .flex-mb.wd-flex .coinBal-flex {
    padding: 0px;
  }
  /* .payee-summary-bank{margin: 0px;} */
  .buy-rpanel .ant-slider-mark-text {
    margin: 0px -8px;
  }
  .sell-rtpanel .sell-header .coinBal-flex {
    justify-content: center;
  }
  .buy-crads-data .order-hstry {
    padding: 0px 10px;
  }
  .buy-crads-data {
    width: 100% !important;
    overflow: auto;
    justify-content: space-between;
    align-items: normal !important;
  }
  .mb-order-1 {
    order: 2;
  }
  /* .mb-order-2{}
.mb-order-3{} */
  .ordrhstry-title {
    font-size: 14px !important;
    text-transform: capitalize;
  }
  .flip-icon .icon.xlg.flip {
    transform: rotate(89deg);
    top: -23px;
    right: -167px;
  }
  .icon.xlg.flip.substract {
    height: 57px;
    width: 39px;
    background-position: -343px -683.5px;
}
  .earn-card {
    margin-top: 12px;
  }
  /* .panel-balhead{font-size: 20px;} */
  .sell-header .coinBal-flex {
    justify-content: left;
    margin-bottom: 8px;
  }
  .order-title {
    display: block !important;
    padding: 0px 8px;
  }
  .grid-search {
    width: 100% !important;
    margin: 6px 0px;
  }
  .search-content {
    margin: 15px;
  }
  .ant-modal-content {
    left: 0px !important;
    width: calc(100% - 0px);
    height: calc(100vh - 120px) !important;
  }
  /* .ant-modal-wrap{ top: 0px !important;} */
  .mobile-space {
    justify-content: normal;
  }
  .mobile-margin {
    margin-left: 16px !important;
  }
  .search-box:hover > .search-text {
    width: 208px !important;
  }
  .withdraw-rightpanel {
    width: auto;
  }
  /* .search-btn {position: absolute;left:0 ;} */
  .p-absolute {
    position: absolute;
    top: -22px;
    left: 5px;
    font-weight: 400;
  }
  .mbl-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .btn-over .tabs-button {
    padding: 0;
  }
  .db-faitwallet .coinval-width {
    white-space: nowrap;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dashboard-transactions {
    margin-top: 16px;
  }
  .mbl-responsiveview {
    margin: 10px 0px;
  }
  .scan-icon {
    display: none;
  }
  .cust-verify {
    width: auto;
    margin: 0 auto;
  }
  .new-custom-radiobtn.mb-24 label:first-child {
    margin-left: -3px;
    margin-right: 10px;
  }
  .new-custom-radiobtn .ant-radio-button-wrapper {
    padding: 0 2px;
  }
  .new-custom-radiobtn .ant-radio-button-wrapper-checked,
  .new-custom-radiobtn .ant-radio-button-wrapper {
    font-size: 14px;
  }
  .sumSub-confirm {
    width: 100%;
    display: block !important;
  }
  .mobile-verification {
    width: 100%;
  }
  .wd-entrysell {
    width: 100% !important;
  }
}

.search-box {
  position: relative;
  height: 46px;
  border-radius: 40px;
  padding: 10px;
  flex: 300px 0 0;
  display: flex;
  align-items: center;
}
.search-box:hover > .search-text {
  width: 240px;
  padding: 8px;
  border: 1px solid #212646;
  border-radius: 6px;
}
.search-text:hover .search-box {
  border: 1px solid #fff;
}
.search-text {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: white;
  font-size: 16px;
  font-weight: normal;
  transition: 0.4s;
  line-height: 26px;
  width: 0px;
  margin-left: 10px;
}
.search-text::placeholder {
  color: #fff;
}
.search-btn {
  position: absolute;
  right: 0;
  z-index: 1;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
}
.cust-drpdwn {
  min-height: 300px;
}
.input-cards {
  height: 50px;
  font-size: 26px;
  background: transparent;
  border: 2px solid #5cc500;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px !important;
}
.get-codeerror {
  height: 50px;
  font-size: 26px;
  background: transparent;
  border: 2px solid #af1225;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px !important;
}
.check-code {
  position: absolute;
  top: -12px;
  left: -5px;
  transform: scale(0.8);
}

.loader-antd {
  text-align: center;
  color: var(--bgWhite);
}
.main-menu .list-item.active::after,
.main-menu .list-item:hover::after,
.main-menu .list-item.active:hover::after {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 3px;
  left: 0;
  right: 0;
  background: #397ef6 !important;
  border-radius: 3px 3px 0 0;
}
.btn-overflowdot {
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.custom-table.card.transactions .table-text.transaction-id-text{color: #397ef6;white-space: nowrap;}
.transaction-id-text{color: #397ef6 !important;}
.summary.tx-space .ant-col:last-child .summary-list-item{border-bottom: 0;}
.wd-entrysell {
  width: 460px;
}
.buy-entryinput .wd-entrysell {
  font-size: 40px;
}
.buy-selection {
  padding: 12px 20px;
}
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 36px;
  color: var(--textWhite);
  background-color: var(--bgDarkGrey);
  justify-content: center;
}
.min-btn {
  background-color: var(--textWhite) !important;
  margin-left: 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
}

/* .input-cards:after{box-shadow: 0 0 0 250px #522d5b;} */
/* @media Start*/
/* end  */


.db-main-title {
  font-weight: 800 !important;
  font-size: 32px !important;
  color: rgba(255, 255, 255, 0.9) !important;
  margin-bottom: 40px !important;
}
.text-style {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgba(255, 255, 255, 0.7) !important;
  margin-top: 16px !important;
}
.cust-text-mt {
  margin-top: 0 !important;
}
.text-personal {
  font-weight: 700;
  font-size: 18px;
  color: #1890ff;
}
.form-block .heading {
  font-size: 25px;
  font-weight: 800;
  color: var(--textWhite);
  margin-bottom: 24px;
  line-height: 28px;
}
.auth0-cust-row {
  position: relative;
}
.auth0-cust-form .cust-input {
  background-color: transparent !important;
  border: 1px solid #6269a3 !important;
  color: #fff;
  border-radius: 8px !important;
  height: 56px;
  margin-bottom: 0 !important;
}
.ant-form-item-has-error.auth0-cust-form .cust-input {border-color: #ff4d4f !important;}
.auth0-cust-form .ant-form-item-label {
  position: absolute;
  background: #131024;
  top: -14px;
  z-index: 1;
  left: 21px;
}
.auth0-cust-form .ant-input-group-addon,
.auth0-cust-form .ant-input {
  background-color: transparent;
}
.auth0-cust-form .ant-input-group-addon {
  border: 0 !important;
}
.cust-input .ant-input {
  height: 56px;
  border-top: 0;
  border-left: 1px solid #6269a3;
  border-right: 0;
  color: #fff;
}
.Approved.field-width.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.cust-input.Approved.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 32px !important;
  color: #fff;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper:hover {
  background-color: transparent !important;
  color: #fff;
}
.custauth-mb {
  margin-bottom: 20px;
}
.custselct-drpdwn.ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.custselct-drpdwn
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 58px !important;
}
.cust-input.Approved.custselct-drpdwn.ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: 56px !important;
}
.pop-btn.cust-color-style {
  background: linear-gradient(
    89.35deg,
    #6613ba 19.31%,
    #2c00aa 51.88%
  ) !important;
  border: 0;
  color: #fff;
  height: 67px;
  border-radius: 42px !important;
  font-size: 18px;
  font-weight: 600;
}
.terms-text {
  color: #8a8a8a;
}
.ant-form .policy-content input[type="radio"],
.ant-form .policy-content input[type="checkbox"] {
  width: 18px;
  height: 18px;
}
.custselct-drpdwn.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 58px !important;
}
.custselct-drpdwn.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 58px !important;
}
.custselct-drpdwn.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 58px !important;
}
.custselct-drpdwn.ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 58px !important;
}
.busimness-mt {
  margin-top: 36px;
}
/* .newcustome-radiocheck .ant-radio-button-wrapper .ant-radio-inner, .ant-radio-button-wrapper input[type='checkbox'], .ant-radio-button-wrapper input[type='radio']{width: 16px;height: 16px;opacity: unset;}
.newcustome-radiocheck .ant-radio-button-wrapper > .ant-radio-button{top:2px;left: -3px;} */
.newcustome-radiocheck {
  display: flex;
}
.newcustome-radiocheck span {
  color: rgba(250, 252, 254, 0.8);
}
.cust-phnimage {
  width: 42px;
}
.auth-custphn {
  margin-left: 10px;
}
.db-mb-cust {
  margin-bottom: 0 !important;
}
/* .cust-auth-alert .ant-alert-message {
  color: #fff;
  text-align: left;
} */
.cust-auth-alert .anticon.ant-alert-icon {
  width: 24px;
    height: 21px;
}
.cust-auth-btn .cust-color-style {
  width: 100%;
  margin-left: auto;
}
.cust-auth-btn .ant-btn-loading {
  display: block !important;
}
.cust-btn-right {
  margin-left: auto;
}
.cust-color-style.ant-btn-loading {
  display: block !important;
}
.sumSub-confirm {
  background: #131024;
  color: #fff;
  padding: 50px 40px 16px;
  height: 350px;
  margin: 80px auto;
  border-radius: 14px;
}
.sumSub-confirm.account-progress{padding: 40px;}
.inactive-text {
  color: red;
}
.buy-bal {
  font-size: 16px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #f00;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.alert-flex.withdraw-alert.fiat-alert {
  top: -205px;
}
.right-panel.withdraw-rightpanel.withdraw-crypto .ant-spin:first-child {
  text-align: center !important;
  width: 100% !important;
}
.left-panel .ant-list.ant-list-split {
  max-height: 100vh;
  overflow-y: auto;
}
.left-panel .ant-list.ant-list-split::-webkit-scrollbar {
  display: none;
}

.carousel-card .carosel-content {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}

.carousel-card {
  background-color: #2c2653;
  border-radius: 13px;
  height: 110px;
  padding: 14px 20px;
  overflow-y: auto;
  margin-bottom: 24px;
}
.ant-carousel .slick-dots li.slick-active {
  width: 30px;
}
.ant-carousel .slick-dots li {
  width: 30px;
}
.address-share {
  color: #99999a !important;
  text-align: center;
  font-size: 18px !important;
  font-weight: 600 !important;
  overflow-wrap: break-word;
}
.text-white1 {
  color: var(--textWhite) !important;
  font-size: 25px;
}
.btn-style.ant-btn[disabled] .icon{opacity: 0.5;}